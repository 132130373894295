import moment from "moment";
import { getItem } from "../SecureStorage";

export const all_components_unit_dict_old = {
  "Solar PV Module": {
    format1: {
      unit_format: "kWp",
      price_format: "Rs. X/Wp",
      total_price: "X *1000 * kWp",
    },
    format2: {
      unit_format: "Nos",
      price_format: "Rs. X Per Nos",
      total_price: "X* Nos",
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Solar Inverter": {
    format1: {
      unit_format: "Nos",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "LT/HT Power Cable 1": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "LT/HT Power Cable 2": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "LT/HT Power Cable 3": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Earthing Cable": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Module Earthing Cable": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Solar DC Cable": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "AC Cables": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Safety Lines": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Fire Extinguisher": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "RMS System": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },

  "Danger Board and Signs": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "GI Strip": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
    format2: {
      unit_format: "Kg",
      price_format: "Rs. X per kg",
      total_price: "X * kgs",
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Earthing Protection System": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Lightning Arrestor": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Cable trays": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Cabling Accessories": {
    format1: {
      unit_format: "Nos",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Wire mesh for protection of Skylights": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Cleaning System": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Communication Cable": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Weather Monitoring Unit": {
    format1: {
      unit_format: "Nos",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Ambient Temperature Sensors": {
    format1: {
      unit_format: "Nos",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Module Temperature Sensors": {
    format1: {
      unit_format: "Nos",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Other Sensors": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "RMS System": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  Pyranometer: {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "SCADA System": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  UPS: {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  Transformer: {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  Walkways: {
    format1: {
      unit_format: "Kg",
      price_format: "Rs. X per kg",
      total_price: "X * kgs",
    },
    format2: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "LT Panel": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "HT Panel": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "AC Distribution (Combiner) Panel Board": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "DC Junction Box (SCB/SMB)": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Metering Panel": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "MDB Breaker": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "HT Breaker": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Bus bar": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Generation Meter": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Zero Export Device": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Reverse Protection Relay": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Net-Meter": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "DG Synchronization": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  Structures: {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "kWp",
      price_format: "Rs. X per Wp",
      total_price: "X *1000* kWp",
    },
    format3: {
      unit_format: "Kg",
      price_format: "Rs. X per kg",
      total_price: "X * kgs",
    },
  },
  "Structure Foundation/\n Pedestal": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Safety Rails": {
    format1: {
      unit_format: "Kg",
      price_format: "Rs. X per kg",
      total_price: "X * kgs",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "Module Mounting Structures with Accessories _ Metal Sheet": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "kWp",
      price_format: "Rs. X per Wp",
      total_price: "X *1000* kWp",
    },
    format3: {
      unit_format: "Kg",
      price_format: "Rs. X per kg",
      total_price: "X * kgs",
    },
  },
  "Module Mounting Structures with Accessories _ Ground Mount": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "kWp",
      price_format: "Rs. X per Wp",
      total_price: "X *1000* kWp",
    },
    format3: {
      unit_format: "Kg",
      price_format: "Rs. X per kg",
      total_price: "X * kgs",
    },
  },
  "Module Mounting Structures with Accessories _ RCC": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "kWp",
      price_format: "Rs. X per Wp",
      total_price: "X *1000* kWp",
    },
    format3: {
      unit_format: "Kg",
      price_format: "Rs. X per kg",
      total_price: "X * kgs",
    },
  },
  "InC Contractor": {
    format1: {
      unit_format: "kWp",
      price_format: "Rs. X per Wp",
      total_price: "X *1000* kWp",
    },
    format2: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
  "MC4 Connectors": {
    format1: {
      unit_format: "Pairs",
      price_format: "Rs. X per pairs",
      total_price: "X * pairs",
    },
    format2: {
      unit_format: "Nos",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format3: {
      unit_format: null,
      price_format: null,
      total_price: null,
    },
  },
};

export const components_list = [
  "AC Distribution (Combiner) Panel Board",
  "Ambient Temperature Sensors",
  "AC Cables",
  "Bus bar",
  "Cable trays",
  "Cabling Accessories",
  "Cleaning System",
  "Communication Cable",
  "DC Junction Box (SCB/SMB)",
  "DG Synchronization",
  "Danger Board and Signs",
  "Earthing Cable",
  "Earthing Protection System",
  "Fire Extinguisher",
  "GI Strip",
  "Generation Meter",
  "HT Breaker",
  "HT Panel",
  "InC Contractor",
  "LT Panel",
  "LT/HT Power Cable 1",
  "LT/HT Power Cable 2",
  "LT/HT Power Cable 3",
  "Lightning Arrestor",
  "MC4 Connectors",
  "MDB Breaker",
  "Metering Panel",
  "Module Earthing Cable",
  "Module Mounting Structures with Accessories _ Ground Mount",
  "Module Mounting Structures with Accessories _ Metal Sheet",
  "Module Mounting Structures with Accessories _ RCC",
  "Module Temperature Sensors",
  "Net-Meter",
  "Other Sensors",
  "Pyranometer",
  "Reverse Protection Relay",
  "SCADA System",
  "Safety Lines",
  "Safety Rails",
  "Solar DC Cables",
  "Solar Inverter",
  "Solar PV Module",
  "Structure Foundation/\n Pedestal",
  "Transformer",
  "UPS",
  "Walkways",
  "Weather Monitoring Unit",
  "Wire mesh for protection of Skylights",
  "Zero Export Device",
  "RMS System",
];

export const lead_source_list = [
  "Channel Partner",
  "Customer Referral",
  "Cold Calling",
  "Email Marketing",
  "Social Media",
  "Add Other",
];

export const project_type_list = [
  { id: "Rooftop", value: "Rooftop" },
  { id: "Industrial", value: "Industrial" },
  { id: "Open Access", value: "Open Access" },
  { id: "Commercial", value: "Commercial" },
  { id: "Ground Mounted", value: "Ground Mount" },
  { id: "Residential", value: "Residential" },
];

export const statesAndUTsList = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];

export const projectSpecDrawings = [
  "Array Layout",
  "PV-Syst",
  "Equipment Layout",
  "DC Stringing Layout",
  "Earthing and LA Layout",
  "Cable Layout",
  "Structure Drawings_RCC",
  "Structure Drawings_Metal Sheet",
  "Structure Drawings_Ground Mount",
  "Civil Layout",
  "Communication System Layout",
  "Walkway Layout",
  "Safety Lifeline Layout",
  "Edge Protection System (Hand Rails) Layout",
  "Project SLD",
  "Module Cleaning System Layout",
  "Final BOQ",
  "Detailed Project Report",
];

export const projectSpecComponents = [
  "Solar PV Module",
  "Inverter",
  "AC Cables",
  "DC Cables",
  "Connectors",
  "Safety Lifeline",
  "Walkways",
  "Inverter Accessories",
  "Earthing Protection System",
  "Lightning Arrestor",
  "Module Mounting Structures with Accessories _ Metal Sheet",
  "Module Mounting Structures with Accessories _ Ground Mount",
  "Module Mounting Structures with Accessories _ RCC",
  "Transformer",
  "DC Junction Box (SCB/SMB)",
  "Metering Panel",
  "ACDB",
  "LT Panel",
  "HT Panel",
  "Cabling",
  "Reverse Power Protection Relay",
  "Meters",
  "DG Synchronization",
  "Communication System",
  "Edge Protection System (Hand Rails)",
  "Zero Export Device",
  "Ladder",
  "Module Cleaning System",
  "Consummables",
  "Fire & Safety System",
  "Wire mesh for protection of Skylights",
  "Danger Boards and Labelling",
  // "I&C Contractor",
  "Cable Trench",
];

export const all_components_unit_dict = {
  "Solar PV Module": {
    format1: {
      unit_format: "kWp",
      price_format: "Rs. X/Wp",
      total_price: "X *1000 * kWp",
    },
    format2: {
      unit_format: "Nos",
      price_format: "Rs. X Per Nos",
      total_price: "X* Nos",
    },
  },
  Inverter: {
    format1: {
      unit_format: "Nos",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "AC Cables": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
  },
  "DC Cables": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
  },
  MC4: {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Pair",
      price_format: "Rs. X per pair",
      total_price: "X* no. of pairs",
    },
  },
  "Y Connectors": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "ACSR Conductor Jointer": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "Termination Kit": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "SS Wire": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
  },
  "Other Accessories": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
  },
  GI: {
    format1: {
      unit_format: "Kg",
      price_format: "Rs. X per kg",
      total_price: "X * kgs",
    },
    format2: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
  },
  FRP: {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
  },
  "Inverter Mounting Stand": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
  },
  Canopy: {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
  },
  "Earthing Strip (GI)": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
    format2: {
      unit_format: "Kg",
      price_format: "Rs. X per kg",
      total_price: "X * kgs",
    },
  },
  "Earthing Cable": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
  },
  "Earth Rod": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "Earth Enhancement Compound": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "Earth Inspection Chamber": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "Conventional Type": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "ESE Type": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "Full Rail": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "kWp",
      price_format: "Rs. X per Wp",
      total_price: "X *1000* kWp",
    },
    // "format3": {
    //     "unit_format": "Rs. X per Wp",
    //     "price_format": "X *1000* kWp",
    //     "total_price": "Kg"
    // }
  },
  "Mini Rail": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "kWp",
      price_format: "Rs. X per Wp",
      total_price: "X *1000* kWp",
    },
    // "format3": {
    //     "unit_format": "Rs. X per Wp",
    //     "price_format": "X *1000* kWp",
    //     "total_price": "Kg"
    // }
  },
  "Micro Rail": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "kWp",
      price_format: "Rs. X per Wp",
      total_price: "X *1000* kWp",
    },
    // "format3": {
    //     "unit_format": "Rs. X per Wp",
    //     "price_format": "X *1000* kWp",
    //     "total_price": "Kg"
    // }
  },
  Clamps: {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "kWp",
      price_format: "Rs. X per Wp",
      total_price: "X *1000* kWp",
    },
    // "format3": {
    //     "unit_format": "Rs. X per Wp",
    //     "price_format": "X *1000* kWp",
    //     "total_price": "Kg"
    // }
  },
  "Other accessories": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "kWp",
      price_format: "Rs. X per Wp",
      total_price: "X *1000* kWp",
    },
    // "format3": {
    //     "unit_format": "Rs. X per Wp",
    //     "price_format": "X *1000* kWp",
    //     "total_price": "Kg"
    // }
  },
  "Ground Mount Structure with Purlin and Rafter": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "kWp",
      price_format: "Rs. X per Wp",
      total_price: "X *1000* kWp",
    },
    // "format3": {
    //     "unit_format": "Rs. X per Wp",
    //     "price_format": "X *1000* kWp",
    //     "total_price": "Kg"
    // }
  },
  "Ground Pile Foundation": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
  },
  "RCC Mount Structure": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "kWp",
      price_format: "Rs. X per Wp",
      total_price: "X *1000* kWp",
    },
    // "format3": {
    //     "unit_format": "Rs. X per Wp",
    //     "price_format": "X *1000* kWp",
    //     "total_price": "Kg"
    // }
  },
  Ballast: {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "Civil Foundation": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
  },
  Transformer: {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "Transformer Yard Foundation": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
  },
  "DC Junction Box (SCB/SMB)": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "Metering Panel": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  ACDB: {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "LT Panel": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "HT Panel": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "Cable tray": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
  },
  "Cable tray cover": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
  },
  "Ladder Type Tray": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
  },
  Conduits: {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
  },
  "Hume Pipe": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
  },
  "Reverse Power Protection Relay": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "Bi-Directional Meter": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "Generation Meter": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "DG Synchronization": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "Communication Cable": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
  },
  "Weather Monitoring Sensors": {
    format1: {
      unit_format: "Nos",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "Data Logger": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "SCADA System": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  UPS: {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "Edge Protection System (Hand Rails)": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
    format2: {
      unit_format: "Kg",
      price_format: "Rs. X per kg",
      total_price: "X * kgs",
    },
    format3: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
  },
  "Zero Export Device": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "Staircase Ladder": {
    format1: {
      unit_format: "Kg",
      price_format: "Rs. X per kg",
      total_price: "X * kgs",
    },
    format2: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "Monkey Ladder": {
    format1: {
      unit_format: "Kg",
      price_format: "Rs. X per kg",
      total_price: "X * kgs",
    },
    format2: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "Water Tank": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "Conduit Pipes": {
    format1: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
    format2: {
      unit_format: "kg ",
      price_format: "Rs. X per kg",
      total_price: "X * kgs",
    },
  },
  "Conduit Fittings": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "Pump Motor": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
  },
  "Cable Tie": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
  },
  "Heat Shrink Sleeve": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
  },
  Ferrules: {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
  },
  "Lugs & Washers": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
  },
  Glands: {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
  },
  "Fire Extinguisher": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
  },
  "Smoke Detector": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
  },
  Hooter: {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
  },
  "Rubber Mat": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
  },
  "Wire mesh for protection of Skylights": {
    format1: {
      unit_format: "Kg",
      price_format: "Rs. X per kg",
      total_price: "X * kgs",
    },
    format2: {
      unit_format: "m",
      price_format: "Rs. X/m",
      total_price: "X * no. of metres",
    },
  },
  "Danger Boards": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
  },
  "Labelling Stickers": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
  },
  Others: {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
  },
  "I&C Contractor": {
    format1: {
      unit_format: "Nos.",
      price_format: "Rs. X Per Nos",
      total_price: "X * Nos",
    },
    format2: {
      unit_format: "Set",
      price_format: "Rs. X Per Set",
      total_price: "X * Set",
    },
  },
};

export const materialStatusList = [
  "Dispatched",
  "Delivered",
  "Unloaded",
  "Stored",
  "Shifting",
];

export const countryCodesList = [
  {
    code: "+91",
    name: "India",
  },
  {
    code: "+7 840",
    name: "Abkhazia",
  },
  {
    code: "+93",
    name: "Afghanistan",
  },
  {
    code: "+355",
    name: "Albania",
  },
  {
    code: "+213",
    name: "Algeria",
  },
  {
    code: "+1 684",
    name: "American Samoa",
  },
  {
    code: "+376",
    name: "Andorra",
  },
  {
    code: "+244",
    name: "Angola",
  },
  {
    code: "+1 264",
    name: "Anguilla",
  },
  {
    code: "+1 268",
    name: "Antigua and Barbuda",
  },
  {
    code: "+54",
    name: "Argentina",
  },
  {
    code: "+374",
    name: "Armenia",
  },
  {
    code: "+297",
    name: "Aruba",
  },
  {
    code: "+247",
    name: "Ascension",
  },
  {
    code: "+61",
    name: "Australia",
  },
  {
    code: "+672",
    name: "Australian External Territories",
  },
  {
    code: "+43",
    name: "Austria",
  },
  {
    code: "+994",
    name: "Azerbaijan",
  },
  {
    code: "+1 242",
    name: "Bahamas",
  },
  {
    code: "+973",
    name: "Bahrain",
  },
  {
    code: "+880",
    name: "Bangladesh",
  },
  {
    code: "+1 246",
    name: "Barbados",
  },
  {
    code: "+1 268",
    name: "Barbuda",
  },
  {
    code: "+375",
    name: "Belarus",
  },
  {
    code: "+32",
    name: "Belgium",
  },
  {
    code: "+501",
    name: "Belize",
  },
  {
    code: "+229",
    name: "Benin",
  },
  {
    code: "+1 441",
    name: "Bermuda",
  },
  {
    code: "+975",
    name: "Bhutan",
  },
  {
    code: "+591",
    name: "Bolivia",
  },
  {
    code: "+387",
    name: "Bosnia and Herzegovina",
  },
  {
    code: "+267",
    name: "Botswana",
  },
  {
    code: "+55",
    name: "Brazil",
  },
  {
    code: "+246",
    name: "British Indian Ocean Territory",
  },
  {
    code: "+1 284",
    name: "British Virgin Islands",
  },
  {
    code: "+673",
    name: "Brunei",
  },
  {
    code: "+359",
    name: "Bulgaria",
  },
  {
    code: "+226",
    name: "Burkina Faso",
  },
  {
    code: "+257",
    name: "Burundi",
  },
  {
    code: "+855",
    name: "Cambodia",
  },
  {
    code: "+237",
    name: "Cameroon",
  },
  {
    code: "+1",
    name: "Canada",
  },
  {
    code: "+238",
    name: "Cape Verde",
  },
  {
    code: "+ 345",
    name: "Cayman Islands",
  },
  {
    code: "+236",
    name: "Central African Republic",
  },
  {
    code: "+235",
    name: "Chad",
  },
  {
    code: "+56",
    name: "Chile",
  },
  {
    code: "+86",
    name: "China",
  },
  {
    code: "+61",
    name: "Christmas Island",
  },
  {
    code: "+61",
    name: "Cocos-Keeling Islands",
  },
  {
    code: "+57",
    name: "Colombia",
  },
  {
    code: "+269",
    name: "Comoros",
  },
  {
    code: "+242",
    name: "Congo",
  },
  {
    code: "+243",
    name: "Congo, Dem. Rep. of (Zaire)",
  },
  {
    code: "+682",
    name: "Cook Islands",
  },
  {
    code: "+506",
    name: "Costa Rica",
  },
  {
    code: "+385",
    name: "Croatia",
  },
  {
    code: "+53",
    name: "Cuba",
  },
  {
    code: "+599",
    name: "Curacao",
  },
  {
    code: "+537",
    name: "Cyprus",
  },
  {
    code: "+420",
    name: "Czech Republic",
  },
  {
    code: "+45",
    name: "Denmark",
  },
  {
    code: "+246",
    name: "Diego Garcia",
  },
  {
    code: "+253",
    name: "Djibouti",
  },
  {
    code: "+1 767",
    name: "Dominica",
  },
  {
    code: "+1 809",
    name: "Dominican Republic",
  },
  {
    code: "+670",
    name: "East Timor",
  },
  {
    code: "+56",
    name: "Easter Island",
  },
  {
    code: "+593",
    name: "Ecuador",
  },
  {
    code: "+20",
    name: "Egypt",
  },
  {
    code: "+503",
    name: "El Salvador",
  },
  {
    code: "+240",
    name: "Equatorial Guinea",
  },
  {
    code: "+291",
    name: "Eritrea",
  },
  {
    code: "+372",
    name: "Estonia",
  },
  {
    code: "+251",
    name: "Ethiopia",
  },
  {
    code: "+500",
    name: "Falkland Islands",
  },
  {
    code: "+298",
    name: "Faroe Islands",
  },
  {
    code: "+679",
    name: "Fiji",
  },
  {
    code: "+358",
    name: "Finland",
  },
  {
    code: "+33",
    name: "France",
  },
  {
    code: "+596",
    name: "French Antilles",
  },
  {
    code: "+594",
    name: "French Guiana",
  },
  {
    code: "+689",
    name: "French Polynesia",
  },
  {
    code: "+241",
    name: "Gabon",
  },
  {
    code: "+220",
    name: "Gambia",
  },
  {
    code: "+995",
    name: "Georgia",
  },
  {
    code: "+49",
    name: "Germany",
  },
  {
    code: "+233",
    name: "Ghana",
  },
  {
    code: "+350",
    name: "Gibraltar",
  },
  {
    code: "+30",
    name: "Greece",
  },
  {
    code: "+299",
    name: "Greenland",
  },
  {
    code: "+1 473",
    name: "Grenada",
  },
  {
    code: "+590",
    name: "Guadeloupe",
  },
  {
    code: "+1 671",
    name: "Guam",
  },
  {
    code: "+502",
    name: "Guatemala",
  },
  {
    code: "+224",
    name: "Guinea",
  },
  {
    code: "+245",
    name: "Guinea-Bissau",
  },
  {
    code: "+595",
    name: "Guyana",
  },
  {
    code: "+509",
    name: "Haiti",
  },
  {
    code: "+504",
    name: "Honduras",
  },
  {
    code: "+852",
    name: "Hong Kong SAR China",
  },
  {
    code: "+36",
    name: "Hungary",
  },
  {
    code: "+354",
    name: "Iceland",
  },
  {
    code: "+62",
    name: "Indonesia",
  },
  {
    code: "+98",
    name: "Iran",
  },
  {
    code: "+964",
    name: "Iraq",
  },
  {
    code: "+353",
    name: "Ireland",
  },
  {
    code: "+972",
    name: "Israel",
  },
  {
    code: "+39",
    name: "Italy",
  },
  {
    code: "+225",
    name: "Ivory Coast",
  },
  {
    code: "+1 876",
    name: "Jamaica",
  },
  {
    code: "+81",
    name: "Japan",
  },
  {
    code: "+962",
    name: "Jordan",
  },
  {
    code: "+7 7",
    name: "Kazakhstan",
  },
  {
    code: "+254",
    name: "Kenya",
  },
  {
    code: "+686",
    name: "Kiribati",
  },
  {
    code: "+965",
    name: "Kuwait",
  },
  {
    code: "+996",
    name: "Kyrgyzstan",
  },
  {
    code: "+856",
    name: "Laos",
  },
  {
    code: "+371",
    name: "Latvia",
  },
  {
    code: "+961",
    name: "Lebanon",
  },
  {
    code: "+266",
    name: "Lesotho",
  },
  {
    code: "+231",
    name: "Liberia",
  },
  {
    code: "+218",
    name: "Libya",
  },
  {
    code: "+423",
    name: "Liechtenstein",
  },
  {
    code: "+370",
    name: "Lithuania",
  },
  {
    code: "+352",
    name: "Luxembourg",
  },
  {
    code: "+853",
    name: "Macau SAR China",
  },
  {
    code: "+389",
    name: "Macedonia",
  },
  {
    code: "+261",
    name: "Madagascar",
  },
  {
    code: "+265",
    name: "Malawi",
  },
  {
    code: "+60",
    name: "Malaysia",
  },
  {
    code: "+960",
    name: "Maldives",
  },
  {
    code: "+223",
    name: "Mali",
  },
  {
    code: "+356",
    name: "Malta",
  },
  {
    code: "+692",
    name: "Marshall Islands",
  },
  {
    code: "+596",
    name: "Martinique",
  },
  {
    code: "+222",
    name: "Mauritania",
  },
  {
    code: "+230",
    name: "Mauritius",
  },
  {
    code: "+262",
    name: "Mayotte",
  },
  {
    code: "+52",
    name: "Mexico",
  },
  {
    code: "+691",
    name: "Micronesia",
  },
  {
    code: "+1 808",
    name: "Midway Island",
  },
  {
    code: "+373",
    name: "Moldova",
  },
  {
    code: "+377",
    name: "Monaco",
  },
  {
    code: "+976",
    name: "Mongolia",
  },
  {
    code: "+382",
    name: "Montenegro",
  },
  {
    code: "+1664",
    name: "Montserrat",
  },
  {
    code: "+212",
    name: "Morocco",
  },
  {
    code: "+95",
    name: "Myanmar",
  },
  {
    code: "+264",
    name: "Namibia",
  },
  {
    code: "+674",
    name: "Nauru",
  },
  {
    code: "+977",
    name: "Nepal",
  },
  {
    code: "+31",
    name: "Netherlands",
  },
  {
    code: "+599",
    name: "Netherlands Antilles",
  },
  {
    code: "+1 869",
    name: "Nevis",
  },
  {
    code: "+687",
    name: "New Caledonia",
  },
  {
    code: "+64",
    name: "New Zealand",
  },
  {
    code: "+505",
    name: "Nicaragua",
  },
  {
    code: "+227",
    name: "Niger",
  },
  {
    code: "+234",
    name: "Nigeria",
  },
  {
    code: "+683",
    name: "Niue",
  },
  {
    code: "+672",
    name: "Norfolk Island",
  },
  {
    code: "+850",
    name: "North Korea",
  },
  {
    code: "+1 670",
    name: "Northern Mariana Islands",
  },
  {
    code: "+47",
    name: "Norway",
  },
  {
    code: "+968",
    name: "Oman",
  },
  {
    code: "+92",
    name: "Pakistan",
  },
  {
    code: "+680",
    name: "Palau",
  },
  {
    code: "+970",
    name: "Palestinian Territory",
  },
  {
    code: "+507",
    name: "Panama",
  },
  {
    code: "+675",
    name: "Papua New Guinea",
  },
  {
    code: "+595",
    name: "Paraguay",
  },
  {
    code: "+51",
    name: "Peru",
  },
  {
    code: "+63",
    name: "Philippines",
  },
  {
    code: "+48",
    name: "Poland",
  },
  {
    code: "+351",
    name: "Portugal",
  },
  {
    code: "+1 787",
    name: "Puerto Rico",
  },
  {
    code: "+974",
    name: "Qatar",
  },
  {
    code: "+262",
    name: "Reunion",
  },
  {
    code: "+40",
    name: "Romania",
  },
  {
    code: "+7",
    name: "Russia",
  },
  {
    code: "+250",
    name: "Rwanda",
  },
  {
    code: "+685",
    name: "Samoa",
  },
  {
    code: "+378",
    name: "San Marino",
  },
  {
    code: "+966",
    name: "Saudi Arabia",
  },
  {
    code: "+221",
    name: "Senegal",
  },
  {
    code: "+381",
    name: "Serbia",
  },
  {
    code: "+248",
    name: "Seychelles",
  },
  {
    code: "+232",
    name: "Sierra Leone",
  },
  {
    code: "+65",
    name: "Singapore",
  },
  {
    code: "+421",
    name: "Slovakia",
  },
  {
    code: "+386",
    name: "Slovenia",
  },
  {
    code: "+677",
    name: "Solomon Islands",
  },
  {
    code: "+27",
    name: "South Africa",
  },
  {
    code: "+500",
    name: "South Georgia and the South Sandwich Islands",
  },
  {
    code: "+82",
    name: "South Korea",
  },
  {
    code: "+34",
    name: "Spain",
  },
  {
    code: "+94",
    name: "Sri Lanka",
  },
  {
    code: "+249",
    name: "Sudan",
  },
  {
    code: "+597",
    name: "Suriname",
  },
  {
    code: "+268",
    name: "Swaziland",
  },
  {
    code: "+46",
    name: "Sweden",
  },
  {
    code: "+41",
    name: "Switzerland",
  },
  {
    code: "+963",
    name: "Syria",
  },
  {
    code: "+886",
    name: "Taiwan",
  },
  {
    code: "+992",
    name: "Tajikistan",
  },
  {
    code: "+255",
    name: "Tanzania",
  },
  {
    code: "+66",
    name: "Thailand",
  },
  {
    code: "+670",
    name: "Timor Leste",
  },
  {
    code: "+228",
    name: "Togo",
  },
  {
    code: "+690",
    name: "Tokelau",
  },
  {
    code: "+676",
    name: "Tonga",
  },
  {
    code: "+1 868",
    name: "Trinidad and Tobago",
  },
  {
    code: "+216",
    name: "Tunisia",
  },
  {
    code: "+90",
    name: "Turkey",
  },
  {
    code: "+993",
    name: "Turkmenistan",
  },
  {
    code: "+1 649",
    name: "Turks and Caicos Islands",
  },
  {
    code: "+688",
    name: "Tuvalu",
  },
  {
    code: "+1 340",
    name: "U.S. Virgin Islands",
  },
  {
    code: "+256",
    name: "Uganda",
  },
  {
    code: "+380",
    name: "Ukraine",
  },
  {
    code: "+971",
    name: "United Arab Emirates",
  },
  {
    code: "+44",
    name: "United Kingdom",
  },
  {
    code: "+1",
    name: "United States",
  },
  {
    code: "+598",
    name: "Uruguay",
  },
  {
    code: "+998",
    name: "Uzbekistan",
  },
  {
    code: "+678",
    name: "Vanuatu",
  },
  {
    code: "+58",
    name: "Venezuela",
  },
  {
    code: "+84",
    name: "Vietnam",
  },
  {
    code: "+1 808",
    name: "Wake Island",
  },
  {
    code: "+681",
    name: "Wallis and Futuna",
  },
  {
    code: "+967",
    name: "Yemen",
  },
  {
    code: "+260",
    name: "Zambia",
  },
  {
    code: "+255",
    name: "Zanzibar",
  },
  {
    code: "+263",
    name: "Zimbabwe",
  },
];

export const getGlobalStagesBasedonCompany = () => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  let stages = [
    {
      name: "PRE-REQUISITES",
      keyType: "pre_requisites",
      tabName: "pre-requisites",
    },
    {
      name: "APPROVALS",
      keyType: "approvals",
      tabName: "approvals",
    },
    {
      name: "ENGINEERING",
      keyType: "engineering",
      tabName: "engineering",
    },
    {
      name: "MATERIALS",
      keyType: "materials",
      tabName: "materials",
    },
    {
      name: "CONSTRUCTION",
      keyType: "construction",
      tabName: "construction",
    },
    {
      name: "SITE HAND OVER",
      keyType: "site_handover",
      tabName: "hand-over",
    },
  ];

  if (userData?.companyName === "Enerparc Energy Pvt Ltd") {
    return [stages[0], ...stages.slice(2, 5), stages[1], stages[5]];
  } else {
    return stages;
  }
};

export const globalStages = getGlobalStagesBasedonCompany();

export const updateStageNameBasedOnCompany = (stage) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  if (userData?.companyName === "Enerparc Energy Pvt Ltd") {
    if (
      stage === "Pre-Requisites" ||
      stage === "PRE-REQUISITES" ||
      stage === "Pre Requisites"
    ) {
      return "BD";
    } else if (stage === "APPROVALS" || stage === "Approvals") {
      return "QUALITY";
    } else if (
      stage === "SITE HAND OVER" ||
      stage === "Site Hand Over" ||
      stage == "site hand over"
    ) {
      return "DOCUMENTATION";
    } else if (stage === "MATERIALS" || stage === "Materials") {
      return "SCM";
    }
    return stage;
  } else {
    return stage;
  }
};

export const getFinanceTitleForfiles = (fileType) => {
  let KYC = [
    "Aadhar and Pan Card",
    "Address Proof(Proprietor and Office)",
    "Company Incorporation Certificate",
    "Ghumastha license/Udyog aadhar",
    "Aadhar and Pan card of all partners",
    "Company Pan card",
    "Address proof of all partners (Proprietor and office)",
    "Directors shareholding list",
    "Partnership deed/LLP Deed",
    "Aadhar and Pan card of of all directors/Shareholders",
    "Address proof of all directors/shareholders (Proprietor and office)",
    "Company Incorporation certificate",
    "Board resolution, MOA, AOA",
  ];
  let GST = [
    "GST Certificate",
    "Month wise sales till date for current year (GST3B)",
  ];
  let BANK_STATEMENTS = [
    "CC/OD statements of all accounts",
    "Bank statement of all accounts - 1yr",
  ];
  let FINANCIALS = ["ITR, Balance sheet & P&L - 3yrs (Audited)"];
  let LOAN_ACCOUNT = ["Loan statements and Sanction letter of all accounts"];
  if (KYC.includes(fileType)) {
    return "KYC";
  } else if (GST.includes(fileType)) {
    return "GST";
  } else if (BANK_STATEMENTS.includes(fileType)) {
    return "BANK STATEMENTS";
  } else if (FINANCIALS.includes(fileType)) {
    return "FINANCIALS";
  } else if (LOAN_ACCOUNT.includes(fileType)) {
    return "LOAN_ACCOUNT";
  }
  return "";
};

export const getStageDocuments = (data) => {
  let docTypeKeys = Object.keys(data?.docs);
  const filteredDocs = {};
  docTypeKeys.forEach((type) => {
    let documents = Object.keys(data?.docs[type]);
    let documentsExists = !documents.includes("NA");

    if (documentsExists) {
      Object.keys(data?.docs[type]).forEach((item) => {
        data.docs[type][item].forEach((i) => {
          if (!filteredDocs.hasOwnProperty(type)) {
            filteredDocs[type] = [];
          }
          filteredDocs[type].push({
            event: i.event,
            upload_date: i.last_updated,
            stage: i.stage,
            name: i.title,
            version: item,
            desc: i.description,
            is_uploaded: true,
            url: i.url,
          });
        });
      });
    } else {
      let noDocumentsItem = data.docs[type][documents[0]][0];

      filteredDocs[type] = [
        {
          stage: noDocumentsItem?.stage,
          event: noDocumentsItem?.event,
          name: noDocumentsItem.title,
          is_uploaded: false,
          desc: null,
          version: null,
          upload_date: null,
          url: null,
        },
      ];
    }
  });

  return filteredDocs;
};

export const getVersions = (filteredDocs) => {
  let versions = {};
  let allStageDocumentVersionsKeys = Object.keys(filteredDocs);
  allStageDocumentVersionsKeys.forEach((item) =>
    filteredDocs[item].forEach((i) => {
      if (!versions.hasOwnProperty(i.version)) {
        versions[i.version] = [];
      }
      versions[i.version] = [...versions[i.version], i];
    })
  );
  return versions;
};

const getTime = (diffMinutes) => {
  let timeAgo;
  if (diffMinutes < 60) {
    timeAgo = `${diffMinutes} minute${diffMinutes === 1 ? "" : "s"} ago`;
  } else if (diffMinutes < 24 * 60) {
    const diffHours = Math.floor(diffMinutes / 60);
    timeAgo = `${diffHours} hour${diffHours === 1 ? "" : "s"} ago`;
  } else if (diffMinutes < 365 * 24 * 60) {
    const diffDays = Math.floor(diffMinutes / (24 * 60));
    timeAgo = `${diffDays} day${diffDays === 1 ? "" : "s"} ago`;
  } else {
    const diffYears = Math.floor(diffMinutes / (365 * 24 * 60));
    timeAgo = `${diffYears} year${diffYears === 1 ? "" : "s"} ago`;
  }
  return timeAgo;
};

export const getDocumentVersionLatestDate = (items) => {
  const dates = items.map((item) =>
    moment(item?.upload_date, "DD-MM-YYYY HH:mm:ss")
  );

  // Find the latest date
  const latestDate = moment.max(dates);
  const diffMinutes = moment().diff(latestDate, "minutes");
  return getTime(diffMinutes);
};

export const getLatestDate = (latestDateStr) => {
  const format = "DD-MM-YYYY HH:mm:ss";
  const latestDate = moment(latestDateStr, format);
  const diffMinutes = moment().diff(latestDate, "minutes");
  return getTime(diffMinutes);
};

export const getCustomerDocs = (data) => {
  return {
    stage_doc_details: {
      "PRE-REQUISITES": {
        "Purchase Order": { event: "", is_uploaded: "", last_updated: "" },
        "Electricity Bills * 3": {
          event: "",
          is_uploaded: "",
          last_updated: "",
        },
        "Auto CAD File": { event: "", is_uploaded: "", last_updated: "" },
        "Electrical SLD": { event: "", is_uploaded: "", last_updated: "" },
        BOM: { event: "", is_uploaded: "", last_updated: "" },
        "Site Checklist": { event: "", is_uploaded: "", last_updated: "" },
        "Escalation Matrix": { event: "", is_uploaded: "", last_updated: "" },
        "Site Feasibility Report": {
          event: "",
          is_uploaded: "",
          last_updated: "",
        },
      },
      APPROVALS: {
        "CEIG Approval": { event: "", is_uploaded: "", last_updated: "" },
        "Net Metering Approval": {
          event: "",
          is_uploaded: "",
          last_updated: "",
        },
      },
      ENGINEERING: {
        "Array Layout": { event: "", is_uploaded: "", last_updated: "" },
        "PV-Syst": { event: "", is_uploaded: "", last_updated: "" },
        "Equipment Layout": { event: "", is_uploaded: "", last_updated: "" },
        "Earthing and LA Layout": {
          event: "",
          is_uploaded: "",
          last_updated: "",
        },
        "Cable Layout": { event: "", is_uploaded: "", last_updated: "" },
        "Cable Calculations": { event: "", is_uploaded: "", last_updated: "" },
        "Structure Drawings_RCC": {
          event: "",
          is_uploaded: "",
          last_updated: "",
        },
        "Structure Drawings_Metal Sheet": {
          event: "",
          is_uploaded: "",
          last_updated: "",
        },
        "Structure Drawings_Ground Mount": {
          event: "",
          is_uploaded: "",
          last_updated: "",
        },
        "Civil Layout": { event: "", is_uploaded: "", last_updated: "" },
        "Busbar calculations for LT and HT panels": {
          event: "",
          is_uploaded: "",
          last_updated: "",
        },
        "Transformer Sizing": { event: "", is_uploaded: "", last_updated: "" },
        "Communication System Layout": {
          event: "",
          is_uploaded: "",
          last_updated: "",
        },
        "Reverse power relay": { event: "", is_uploaded: "", last_updated: "" },
        Walkways: { event: "", is_uploaded: "", last_updated: "" },
        "Safety Line": { event: "", is_uploaded: "", last_updated: "" },
        "Safety Rails": { event: "", is_uploaded: "", last_updated: "" },
        "Project SLD": { event: "", is_uploaded: "", last_updated: "" },
        "Final BOQ": { event: "", is_uploaded: "", last_updated: "" },
        "Detailed Project Report": {
          event: "",
          is_uploaded: "",
          last_updated: "",
        },
      },
      MATERIALS: {
        "Customer Copy Invoice": {
          event: "",
          is_uploaded: "",
          last_updated: "",
        },
      },
      CONSTRUCTION: {
        "Site Handover Certificate": {
          event: "",
          is_uploaded: "",
          last_updated: "",
        },
      },
      "SITE HAND OVER": {
        "Detailed Project Report": {
          event: "",
          is_uploaded: "",
          last_updated: "",
        },
        "Module Warranty Certificate": {
          event: "",
          is_uploaded: "",
          last_updated: "",
        },
        "Inverter Warranty Certificate": {
          event: "",
          is_uploaded: "",
          last_updated: "",
        },
        "Inverter Data Sheet": { event: "", is_uploaded: "", last_updated: "" },
        "Module Data sheet": { event: "", is_uploaded: "", last_updated: "" },
        "Required Invoices": { event: "", is_uploaded: "", last_updated: "" },
        "Government Approvals Documents": {
          event: "",
          is_uploaded: "",
          last_updated: "",
        },
      },
    },
  };
};

export function getVersionNumber(orderString) {
  let regex = /(.*)\sV(\d+)/;

  const match = orderString.match(regex);

  if (match) {
    return [match[1], match[2]];
  }
}

//  Site Visit - Transformer,DG,Spare Feeder,LT Panel fields

export const getSiteVisitFormFields = (title) => {
  if (title === "Transformer") {
    return [
      {
        name: "rating",
        label: "Rating",
        placeholder: "Rating*",
        required: true,
        suffix: "KVA",
        type: "input",
      },
      {
        name: "hv",
        label: "HV Voltage",
        placeholder: "HV Voltage*",
        required: true,
        suffix: "KV/V",
        type: "input",
      },
      {
        name: "lv",
        label: "LV Voltage",
        placeholder: "LV Voltage*",
        required: true,
        suffix: "KV/V",
        type: "input",
      },
    ];
  } else if (title === "DG") {
    return [
      {
        name: "rating",
        label: "Rating",
        placeholder: "Rating*",
        required: true,
        suffix: "KVA",
        type: "input",
      },
      {
        name: "type",
        label: "Type",
        placeholder: "Select Type",
        required: true,
        type: "select",
      },
    ];
  } else if (title === "LT Panel" || title === "Spare Feeder") {
    return [
      {
        name: "rating",
        label: "Rating",
        placeholder: "Rating*",
        required: true,
        suffix: "AMPS",
        type: "input",
      },
    ];
  } else if (title === "LA") {
    return [
      {
        name: "range",
        label: "Range",
        placeholder: "Range*",
        required: true,
        suffix: "m",
        type: "input",
      },
      {
        name: "type",
        label: "Type",
        placeholder: "Select Type",
        required: true,
        type: "select",
      },
    ];
  }
};

export const checkIfBoolean = (data) => {
  if (typeof data === "boolean") {
    return data;
  }
  return null;
};

export const processFiles = (filelist, data, type) => {
  let files = [];
  for (let i = 0; i < filelist?.length; i++) {
    files.push({
      id: data[type] ? data[type][i] : null,
      file: filelist[i],
    });
  }
  return {
    files: [],
    existing_files: files,
    showFile: false,
  };
};

export const sendFiles = (files, imageType, formData) => {
  for (let i = 0; i < files?.length; i++) {
    formData.append(imageType, files[i]);
  }
};

export const mergeFiles = (imageDetails) => [
  ...(imageDetails?.files || []),
  ...(imageDetails?.existing_files || []),
];

export const getUnitsByTitle = (title) => {
  if (
    [
      "MODULES",
      "MMS",
      "INVERTERS",
      "LT Panel",
      "Walkway",
      "Safety Line",
      "Bus Duct",
      "AC Cable",
    ].includes(title)
  ) {
    return "Nos";
  } else if (
    [
      "Handrail",
      "BOS & I&C",
      "Extension Panel",
      "SCADA",
      "BOS Clamps",
      "DC Cable",
    ].includes(title)
  ) {
    return "mtrs";
  } else if (title?.includes("BOS-07") || title?.includes("BOS-08")) {
    return "mtrs";
  } else {
    return "Nos";
  }
};

export const projectRoles = [
  {
    value: "Project Manager",
    role: "Project Manager",
    text: "Has access to view complete details of the project and edit everything",
  },
  {
    value: "Sales",
    role: "Sales",
    text: "Can see customer-related details",
  },
  {
    value: "Liasoning Manager",
    role: "Liasoning Manager",
    text: "Approves everything in the Approval Stage",
  },
  {
    value: "Liasoning Officer",
    role: "Liasoning Officer",
    text: "Works in the Approvals Stage",
  },
  {
    value: "Designer",
    role: "Designer",
    text: "Works in the Engineering Stage",
  },
  {
    value: "Lead Designer",
    role: "Lead Designer",
    text: "Approves everything in the Engineering Stage",
  },
  {
    value: "Procurement Manager",
    role: "Procurement Manager",
    text: "Approves everything in the Procurement Stage",
  },
  {
    value: "Procurement Executive",
    role: "Procurement Executive",
    text: "Works in the Procurement Stage",
  },
  {
    value: "Site In-Charge",
    role: "Site In-Charge",
    text: "Approves everything in the Construction Stage",
  },
  {
    value: "Site Executive",
    role: "Site Executive",
    text: "Works in the Construction Stage",
  },
  {
    value: "O&M Manager",
    role: "O&M Manager",
    text: "Access to all required project-related data to operate the plant post commissioning",
  },
];

export const getProjectId = () => {
  let projectId;
  if (getItem("projectId")) {
    projectId = JSON.parse(getItem("projectId"));
  }
  return projectId;
};

export const getToken = () => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  return userData?.authToken;
};

export const getCompanyName = () => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  return userData?.companyName;
};

export const enerparcUrls = [
  "update_scm_status/",
  "get_scm_details/",
  "generate_scm_report/",
  "generate_construction_report/",
  "get_scm_dashboard/",
  "add_material_installation_checklist/",
  "update_material_installation_checklist/",
  "add_construction_status/",
  "delete_material_installation_checklist/",
  "add_daily_manpower_details/",
  "approve_engineering_doc/",
  "add_construction_planning/",
  "update_construction_planning/",
  "add_material_quantity_details/",
  "update_material_quantity_details/",
  "approve_mdcc_doc/",
  "get_construction_dashboard/",
  "update_enerparc_project_details/",
  "update_event_date/",
  "get_engineering_dashboard/",
  "get_event/:id/",
  "add_enerparc_new_material/",
  "update_enerparc_material/",
  "add_enerparc_new_drawing/",
  "update_enerparc_drawing/",
  "get_planning_stages/",
  "get_project_draft/",
];

export const bridgewayUrls = [
  "add_ticket/",
  "get_company_tickets/",
  "change_ticket_status/",
  "update_ticket/",
  "reassign_ticket/",
  "update_net_metering_stage/",
  "get_net_metering_details/",
  "crm/add_lead/",
  "crm/update_lead/",
  "crm/get_all_boards/",
  "crm/get_all_members/",
  "crm/add_team_member/",
  "crm/get_lead_details/",
  "crm/add_comment/",
  "crm/add_attachment/",
  "crm/update_stages/",
  "crm/get_company_details/",
  "crm/add_lead_company/",
  "crm/get_all_companies/",
  "crm/update_companies/",
  "crm/rename_stage/",
  "crm/add_stage/",
  "crm/update_stage/",
  "crm/add_channel_partner/",
  "crm/get_all_channel_partners/",
  "crm/delete_lead/",
  "crm/delete_company/",
  "crm/delete_lead_company_list/",
  "crm/update_lead_project_commercial/",
  "crm/upload_lead_file/",
  "crm/get_all_activity/",
  "crm/update_order/",
  "crm/update_task/",
  "crm/approve_lead/",
  "get_notifications/",
];

export const manikaranUrls = ["add_project/", "get_planning_stages/"];
export const companies = {
  bridgeway: "Bridgeway Power Services Pvt. Ltd.",
  enerparc: "Enerparc Energy Pvt Ltd",
  manikaran: "Manikaran Renewables Limited",
};

export const getApiEndpoint = (endpoint, companyName) => {
  const baseURL = process.env.REACT_APP_BASE_URL;

  // Map companies to their API paths
  const companyPaths = {
    "Bridgeway Power Services Pvt. Ltd.": {
      path: "bridgeway",
      urls: bridgewayUrls,
    },
    "Enerparc Energy Pvt Ltd": {
      path: "enerparc",
      urls: enerparcUrls,
    },
    "Manikaran Renewables Limited": {
      path: "manikaran",
      urls: manikaranUrls,
    },
  };

  const companyData = companyPaths[companyName];

  if (companyData) {
    const { path, urls } = companyData;

    // Check if the endpoint exists in the allowed URLs
    if (urls.includes(endpoint)) {
      return `${baseURL}${path}/${endpoint}`;
    }
    // Check for dynamic pattern match (e.g., "get_event/:id")
    const isDynamicMatch = urls.some((url) => {
      if (url.includes(":id")) {
        const pattern = new RegExp(`^${url.replace(":id", "\\d+")}$`);
        return pattern.test(endpoint);
      }
      return false;
    });

    if (isDynamicMatch) {
      return `${baseURL}${path}/${endpoint}`;
    }
  }

  // Return the URL without the company path if not found
  return `${baseURL}${endpoint}`;
};

export const isAccessRestricted = (eventData) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  if (userData?.companyName === "Enerparc Energy Pvt Ltd") {
    return !(eventData?.can_edit || eventData?.can_approve);
  }
  return !eventData?.can_work && !eventData?.can_approve;
};

export const isEndTaskRestricted = (eventData) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  if (userData?.companyName === "Enerparc Energy Pvt Ltd") {
    return !(
      eventData?.can_approve ||
      userData?.department === "Management" ||
      userData?.department === "Project Manager"
    );
  }
  return !eventData?.can_work && !eventData?.can_approve;
};

export const isDocumentAccessRestricted = (eventData) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  if (userData?.companyName === "Enerparc Energy Pvt Ltd") {
    return !(
      eventData?.can_view ||
      eventData?.can_edit ||
      eventData?.can_approve
    );
  }
  return !eventData?.can_work && !eventData?.can_approve;
};

// export const isDisabledProjectActions = () => {
//   if (userData?.companyName === "Enerparc Energy Pvt Ltd") {
//     return !canEdit?.includes("all_dashboard");
//   }
//   return false;
// };

export const handleEnerparcDateReview = (setState) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  if (userData?.companyName === "Enerparc Energy Pvt Ltd") {
    setState(true);
  }
};

export const updateEnerparcDatesBasedOnStart = (
  start_date,
  end_date,
  new_start_date
) => {
  const oldStartDate = moment(start_date, "DD/MM/YYYY");
  const oldCompleteDate = moment(end_date, "DD/MM/YYYY");
  const newStartDate = moment(new_start_date, "DD/MM/YYYY");

  // Calculate the difference in days
  const diffInDays = newStartDate.diff(oldStartDate, "days");
  const newCompleteDate = moment(oldCompleteDate).add(diffInDays, "days");
  return {
    startDate: newStartDate.format("DD/MM/YYYY"),
    endDate: newCompleteDate.format("DD/MM/YYYY"),
  };
};
export const updateEnerparcDatesBasedOnEnd = (start_date, new_end_date) => {
  const startDate = moment(start_date, "DD/MM/YYYY");
  const newCompleteDate = moment(new_end_date, "DD/MM/YYYY");
  return {
    startDate: startDate.format("DD/MM/YYYY"),
    endDate: newCompleteDate.format("DD/MM/YYYY"),
  };
};
