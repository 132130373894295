import React from "react";
import { getItem } from "../../SecureStorage";

const Login = React.lazy(() => import("../../components/Auth/Login"));
const SignUp = React.lazy(() => import("../signup.route"));
const SignupSplashScreen = React.lazy(() =>
  import("../../components/Auth/SignupSplashScreen")
);

const DashBoard = React.lazy(() => import("../dashboard.route"));

const LoanCentre = React.lazy(() =>
  import("../../components/LoanCentre/LoanCentre")
);
const AccountRoute = React.lazy(() => import("../account.route"));

const CustomerDashboard = React.lazy(() =>
  import("../customer-dashboard.route")
);
const CustomerLoanCentre = React.lazy(() =>
  import("../../components/customerLoanCentre/CustomerLoanCentre")
);

const SCMDashboard = React.lazy(() =>
  import("../../components/scmDashboard/SCMDashboard")
);
const ConstructionDashboard = React.lazy(() =>
  import("../../components/constructionDashboard/ConstructionDashboard")
);

const InventoryPage = React.lazy(() =>
  import("../../components/Inventory/InventoryPage")
);
const AddQuoteModal = React.lazy(() =>
  import("../../components/Auth/AddQuoteModal")
);

const StageItem = React.lazy(() =>
  import("../../components/InnerComponents/StageItem")
);
const ThankYou = React.lazy(() => import("../../components/Auth/ThankYou"));

const DocsRoute = React.lazy(() => import("../docs.route"));
const Photos = React.lazy(() => import("../photos.route"));
const PhotosStageItem = React.lazy(() =>
  import("../../components/InnerComponents/VendorPhotosStage")
);
const PostProjectDetails = React.lazy(() =>
  import("../../components/Projects/PostProjectDetails")
);
const CreateNewProject = React.lazy(() =>
  import("../../components/Projects/CreateNewProject/CreateNewProject")
);
const PrivacyPolicy = React.lazy(() =>
  import("../../components/Privacy-Policy/PrivacyPolicy")
);

const GanttEventRoute = React.lazy(() => import("../gantt-events.route"));
const WorkTableRoute = React.lazy(() => import("../work-table.route"));
const InnerProjectDashboard = React.lazy(() =>
  import("../../components/InnerComponents/InnerProjectDashboard")
);
const ProjectProgress = React.lazy(() =>
  import("../../components/ProjectProgress/ProjectProgress")
);
const NotificationRoute = React.lazy(() => import("../notifictaion.route"));
const ProjectLevelTeamPage = React.lazy(() =>
  import("../../components/InnerComponents/ProjectLevelTeamPage")
);
const MaterialUsedPage = React.lazy(() =>
  import("../../components/InnerComponents/MaterialUsedPage")
);
const ProjectDocs = React.lazy(() =>
  import("../../components/InnerComponents/ProjectDocs")
);
const CommingSoonPage = React.lazy(() =>
  import("../../components/InnerComponents/CommingSoonPage")
);
const ResponsibilityMatrix = React.lazy(() =>
  import("../../components/Projects/ResponsibilityMatrix")
);

const OnboardingSupplierModal = React.lazy(() =>
  import("../../components/Auth/OnboardingSupplierModal")
);
const SampleGantts = React.lazy(() => import("../sample-gantts.route"));
const SampleGanttDetails = React.lazy(() =>
  import("../../components/SampleGantts/SampleGanttDetails")
);

const ProjectFinanceModule = React.lazy(() =>
  import("../../components/Finance/FinanceDashboard")
);
const LandingPage = React.lazy(() =>
  import("../../components/LandingPage/LandingPage")
);
const ProjectSpcification = React.lazy(() =>
  import("../../components/InnerComponents/Spcification")
);
const TeamsSubContractors = React.lazy(() =>
  import("../../components/Teams/SwitchTabs")
);
const TeamsSuppliers = React.lazy(() =>
  import("../../components/Suppliers/SuppliersPage")
);
const ProjectReports = React.lazy(() =>
  import("../../components/Reports/ProjectReports")
);
const ProjectPhotos = React.lazy(() =>
  import("../../components/ProjectPhotos/ProjectPhotos")
);
const CRMRoute = React.lazy(() => import("../crm.route"));
const ProjectPlanningRoute = React.lazy(() =>
  import("../project-planning.route")
);
const TicketDashboard = React.lazy(() =>
  import("../../components/InnerComponents/TicketDashboard/TicketDashboard")
);
const EPCsRoute = React.lazy(() => import("../epcs.route"));
const ProjectManagersRoute = React.lazy(() =>
  import("../project-managers.route")
);
const FinancePage = React.lazy(() =>
  import("../../components/LandingPage/Finance/FinancingPage")
);
const RequestFinancingMobile = React.lazy(() =>
  import(
    "../../components/LandingPage/Finance/MobileVersion/RequestFinancingMobile"
  )
);
const FinanceSignUp = React.lazy(() =>
  import(
    "../../components/LandingPage/Finance/MobileVersion/FinanceSignupMobile"
  )
);
const ProposalSignup = React.lazy(() =>
  import("../../components/Auth/ProposalSignup")
);
const Onboarding = React.lazy(() => import("../onboarding.route"));

const EngineeringDashboard = React.lazy(() =>
  import("../../components/engineeringDashboard/EngineeringDashboard")
);
const OnmRoute = React.lazy(() => import("../onm.route"));
const AuctionReRoute = React.lazy(() => import("../auction-reroute"));
const SiteVisit = React.lazy(() =>
  import("../../components/SiteVisit/SiteVisit")
);
const SiteTabs = React.lazy(() =>
  import("../../components/SiteVisit/SiteTabs")
);
const EcomReRoute = React.lazy(() => import("../ecom-reroute"));
const NoInternet = React.lazy(() => import("../../components/NoInternet"));

const BridgewayLeadForm = React.lazy(() =>
  import("../../components/Bridgeway/BridgewayLeadForm")
);
const BridgewayTicketForm = React.lazy(() =>
  import("../../components/Bridgeway/BridgewayTicketForm")
);

let secureStorage;

if (getItem("userData")) {
  secureStorage = JSON.parse(getItem("userData"));
}

export const authRoutes = [
  {
    path: "/",
    component: LandingPage,
    exact: true,
    props: { selectedLogin: "Project Management Tool" },
  },
  { path: "/thankyoupage", component: ThankYou, exact: true },
  { path: "/login", component: Login, exact: true },
  { path: "/signup", component: SignUp, exact: true },
  { path: "/privacy-policy", component: PrivacyPolicy, exact: true },
  { path: "/signup/next", component: SignupSplashScreen, exact: true },
  { path: "/financing", component: FinancePage, exact: true },
  {
    path: "/request-financing",
    component: RequestFinancingMobile,
    exact: true,
  },
  { path: "/financing/signup", component: FinanceSignUp, exact: true },
  { path: "/proposal/signup", component: ProposalSignup, exact: true },
  { path: "/onboarding", component: Onboarding, exact: true },
];

export const consumerRoutes = [
  { path: "/projects/project-gantt", component: GanttEventRoute, exact: true },
  { path: "/customer-dashboard", component: CustomerDashboard, exact: true },
  { path: "/notifications", component: NotificationRoute, exact: true },
  { path: "/account", component: AccountRoute, exact: true },
  { path: "/customer-loan-centre", component: CustomerLoanCentre, exact: true },
  { path: "/docs", component: DocsRoute, exact: true },
  { path: "/stage", component: StageItem, exact: true },
  { path: "/", component: LandingPage, exact: true },
  { path: "/ticket-dashboard", component: TicketDashboard, exact: true },
  { path: "/auction", component: AuctionReRoute },
];

export const salesRoutes = [
  {
    path: "/projects/project-dashboard",
    component: InnerProjectDashboard,
    exact: true,
  },
  { path: "/projects/project-gantt", component: GanttEventRoute, exact: true },
  { path: "/projects/photos/stage", component: PhotosStageItem, exact: true },
  { path: "/team", component: TeamsSubContractors, exact: true },
  { path: "/dashboard", component: DashBoard },
  { path: "/photos", component: Photos, exact: true },
  { path: "/notifications", component: NotificationRoute, exact: true },
  { path: "/account", component: AccountRoute, exact: true },
  { path: "/", component: LandingPage, exact: true },
  { path: "/privacy-policy", component: PrivacyPolicy, exact: true },
  { path: "/projects/work-table", component: WorkTableRoute, exact: true },
  {
    path: "/projects/project-progress",
    component: ProjectProgress,
    exact: true,
  },
  { path: "/projects/project-documents", component: ProjectDocs, exact: true },
  { path: "/stage", component: StageItem, exact: true },
  { path: "/financing", component: FinancePage, exact: true },
  {
    path: "/request-financing",
    component: RequestFinancingMobile,
    exact: true,
  },
  { path: "/financing/signup", component: FinanceSignUp, exact: true },
  { path: "/crm", component: CRMRoute },
  // ...(isCrm ? [{ path: "/crm", component: CRMRoute }] : []),
];

export const vendorRoutes = [
  {
    path: "/projects/project-dashboard",
    component: InnerProjectDashboard,
    exact: true,
  },
  { path: "/projects/project-gantt", component: GanttEventRoute, exact: true },
  { path: "/projects/work-table", component: WorkTableRoute, exact: true },

  ...(!secureStorage?.isProjectManager
    ? [
        {
          path: "/projects/project-finance",
          component: ProjectFinanceModule,
          exact: true,
        },
      ]
    : []),
  {
    path: "/projects/project-progress",
    component: ProjectProgress,
    exact: true,
  },
  { path: "/projects/project-reports", component: ProjectReports, exact: true },
  { path: "/projects/project-documents", component: ProjectDocs, exact: true },
  {
    path: "/projects/engineering-dashboard",
    component: EngineeringDashboard,
    exact: true,
  },
  { path: "/projects/scm-dashboard", component: SCMDashboard, exact: true },
  {
    path: "/projects/construction-dashboard",
    component: ConstructionDashboard,
    exact: true,
  },
  {
    path: "/projects/project-team",
    component: ProjectLevelTeamPage,
    exact: true,
  },
  { path: "/projects/material-used", component: MaterialUsedPage, exact: true },
  { path: "/team", component: TeamsSubContractors, exact: true },
  { path: "/suppliers", component: TeamsSuppliers, exact: true },
  { path: "/inventory", component: InventoryPage, exact: true },
  { path: "/dashboard", component: DashBoard },
  { path: "/loan-centre", component: LoanCentre, exact: true },
  { path: "/docs", component: DocsRoute, exact: true },
  { path: "/project-specification", component: CreateNewProject, exact: true },
  // { path: "/project-planning", component: PostProjectDetails },
  { path: "/notifications", component: NotificationRoute, exact: true },
  {
    path: "/responsibility-matrix",
    component: ResponsibilityMatrix,
    exact: true,
  },
  { path: "/sample-gantts", component: SampleGantts, exact: true },
  { path: "/account", component: AccountRoute, exact: true },
  {
    path: "/sample-gantt-details/:gantt_id",
    component: SampleGanttDetails,
    exact: true,
  },
  {
    path: "/projects/specifications",
    component: ProjectSpcification,
    exact: true,
  },
  {
    path: "/projects/project-analytics",
    component: CommingSoonPage,
    exact: true,
  },
  { path: "/", component: LandingPage, exact: true },
  { path: "/projects/planning", component: ProjectPlanningRoute },
  // Add CRM route conditionally based on isCrm
  { path: "/crm", component: CRMRoute },
  // ...(isCrm ? [{ path: "/crm", component: CRMRoute }] : []),
  { path: "/ticket-dashboard", component: TicketDashboard, exact: true },
  { path: "/stage", component: StageItem, exact: true },
  { path: "/projects/photos/stage", component: PhotosStageItem, exact: true },
  { path: "/sitevisit", component: SiteVisit, exact: true },
  { path: "/sitetabs", component: SiteTabs, exact: true },
  {
    path: "/projects/photos/stage/add",
    component: PhotosStageItem,
    props: { isAddPhoto: true },
    exact: true,
  },
  // Add EPCs and Project Managers routes conditionally based on isProjectManager
  ...(secureStorage?.isProjectManager
    ? [
        { path: "/epcs", component: EPCsRoute, exact: true },
        {
          path: "/project-managers",
          component: ProjectManagersRoute,
          exact: true,
        },
      ]
    : []),
  { path: "/financing", component: FinancePage, exact: true },
  {
    path: "/request-financing",
    component: RequestFinancingMobile,
    exact: true,
  },
  { path: "/financing/signup", component: FinanceSignUp, exact: true },
  { path: "/signup/next", component: SignupSplashScreen, exact: true },
  { path: "/onboarding", component: Onboarding, exact: true },
  { path: "/auction", component: AuctionReRoute },
  { path: "/ecom", component: EcomReRoute },
  { path: "/operations", component: OnmRoute },
];

export const onBoardSupplierRoutes = [
  {
    path: "/onboard-suppliers",
    component: OnboardingSupplierModal,
    exact: true,
  },

  {
    path: "/",
    component: LandingPage,
    exact: true,
    props: { selectedLogin: "Project Management Tool" },
  },

  { path: "/financing", component: FinancePage, exact: true },
  {
    path: "/request-financing",
    component: RequestFinancingMobile,
    exact: true,
  },
  { path: "/financing/signup", component: FinanceSignUp, exact: true },
];

export const bridgewayTicketFormRoutes = [
  {
    path: "/bridgeway-tickets-forms",
    component: BridgewayTicketForm,
    exact: true,
  },

  {
    path: "/",
    component: LandingPage,
    exact: true,
    props: { selectedLogin: "Project Management Tool" },
  },
];

export const bridgewayleadFormRoutes = [
  {
    path: "/bridgeway-lead-forms",
    component: BridgewayLeadForm,
    exact: true,
  },

  {
    path: "/",
    component: LandingPage,
    exact: true,
    props: { selectedLogin: "Project Management Tool" },
  },
];
export const rfqRoutes = [
  { path: "/addQuote", component: AddQuoteModal, exact: true },
  { path: "/thankyoupage", component: ThankYou, exact: true },
  {
    path: "/",
    component: LandingPage,
    exact: true,
    props: { selectedLogin: "Project Management Tool" },
  },

  { path: "/financing", component: FinancePage, exact: true },
  {
    path: "/request-financing",
    component: RequestFinancingMobile,
    exact: true,
  },
  { path: "/financing/signup", component: FinanceSignUp, exact: true },
];

export const noInternetRoutes = [
  { path: "/no-internet", component: NoInternet, exact: true },
];
