import {
  SIGNUP_USER_LOADING,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  LOGIN_USER_LOADING,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  GET_COMPANY_DETAILS_LOADING,
  GET_COMPANY_DETAILS_SUCCESS,
  GET_COMPANY_DETAILS_FAILURE,
  GET_EMAIL_CONFIRMATION_LOADING,
  GET_EMAIL_CONFIRMATION_SUCCESS,
  GET_EMAIL_CONFIRMATION_FAILURE,
  VERIFY_OTP_LOADING,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  ACTIVATE_USER_LOADING,
  ACTIVATE_USER_SUCCESS,
  ACTIVATE_USER_FAILURE,
  ONBOARD_VENDOR_LOADING,
  ONBOARD_VENDOR_SUCCESS,
  ONBOARD_VENDOR_FAILURE,
} from "../types";

import axios from "axios";
import { message } from "antd";
import { clearItems, getItem, removeItem, setItem } from "../../SecureStorage";
import { Mixpanel } from "../../Mixpanel";
import mixpanel from "mixpanel-browser";

import { ssoAuthenticator } from "../sso";
import { authSuccess } from "../../reducers/auth-reducers";

export const signUpUser = (user) => (dispatch) => {
  dispatch({ type: SIGNUP_USER_LOADING, payload: null });
  axios
    .post(`${process.env.REACT_APP_BASE_URL}signup/`, user)
    .then((result) => {
      dispatch({ type: SIGNUP_USER_SUCCESS, payload: result.data });
    })
    .catch((e) => {
      dispatch({
        type: SIGNUP_USER_FAILURE,
        payload: { message: e.response.data.msg },
      });
    });
};

export const loginUser = (user) => (dispatch) => {
  // https://pmt.safearth-api.in/rest-auth/login/
  // http://3.111.47.174/rest-auth/login/
  dispatch({ type: LOGIN_USER_LOADING, payload: null });
  axios.defaults.headers = {
    "Content-Type": "application/json",
  };
  axios
    .post("https://pmt.safearth-api.in/rest-auth/login/", user)
    .then((result) => {
      const userData = {
        token: result.data.key,
        username: user.get("username"),
        userId: result.data.user,
        is_consumer: result.data.user_type.is_consumer,
        is_vendor: result.data.user_type.is_vendor,
        is_admin: result.data.user_type.is_admin,
        name: result.data.user_type.name,
        company_name: result.data.user_type.company_name,
        website: result.data.user_type.website,
        phone: result.data.user_type.phone_number,
      };
      let signupToken = result.data.key;
      setItem("signupToken", signupToken);
      dispatch({ type: LOGIN_USER_SUCCESS, payload: result.data });
      if (result.data?.user_type.phone_number) {
        setItem("mobile_number", result.data?.user_type.phone_number);
      }
      setItem("userEmail", user.get("username"));
      Mixpanel.identify(user.get("username"));
      Mixpanel.people.set({
        $name: user.get("username") + "\n" + JSON.parse(getItem("companyName")),
        $email: user.get("username"),
        Company: JSON.parse(getItem("companyName")),
      });
      mixpanel.time_event("Session started");
      setItem("sessionStartTime", new Date().getTime());
      Mixpanel.track("Session started", {
        session_started_time: new Date().toLocaleString("en-US", {
          timeZone: "Asia/Kolkata",
        }),
      });

      ssoAuthenticator(userData, signupToken, dispatch, user.get("password"));
    })
    .catch((e) => {
      message.error(e?.response?.data?.non_field_errors?.[0]);
      dispatch({
        type: LOGIN_USER_FAILURE,
        payload: { message: e?.response?.data?.non_field_errors?.[0] },
      });
    });
};

// get company details
export const getCompanyDetails = (username, password) => (dispatch) => {
  dispatch({ type: GET_COMPANY_DETAILS_LOADING, payload: null });
  const form = new FormData();
  form.append("username", username);
  form.append("password", password);

  message.info("Loading...");
  axios
    .post("https://safearth-api.in/rest-auth/login/", form)
    .then((res) => {
      if (res.data.key) {
        const token = res.data.key;
        axios.defaults.headers = {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        };
        axios
          .get("https://safearth-api.in/users/get_vendor_details")
          .then((res) => {
            const companyDetails = res.data.vendor;
            dispatch({
              type: GET_COMPANY_DETAILS_SUCCESS,
              payload: companyDetails,
            });
          })
          .catch((err) => message.error(err.message));
      }
      dispatch({ type: GET_COMPANY_DETAILS_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: GET_COMPANY_DETAILS_FAILURE, payload: err.message });
    });
};

// get email confirmation
export const getEmailConfirmation = (email) => (dispatch) => {
  axios.defaults.headers = {
    "Content-Type": "application/json",
  };
  axios
    .get(`${process.env.REACT_APP_BASE_URL}send_email_confirmation/`, {
      params: {
        email: email,
      },
    })
    .then((res) =>
      message.success(
        "Confirmation email has been sent to the provided email address."
      )
    )
    .catch((err) => message.error(err.message));
};

// Send Login email OTP

export const sendEmailOtp = (email) => (dispatch) => {
  axios.defaults.headers = {
    "Content-Type": "application/json",
  };
  axios
    .get(`${process.env.REACT_APP_BASE_URL}send_login_email_confirmation/`, {
      params: {
        email: email,
      },
    })
    .then((res) => res)
    .catch((err) => message.error(err.message));
};

// verify login otp
export const verifyLoginOtp = (form) => async (dispatch) => {
  axios.defaults.headers = {
    "Content-Type": "application/json",
  };

  try {
    let res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}verify_login_otp/`,
      form
    );

    if (res?.data?.status === "FAIL") {
      message.error(res?.data?.response);
      return;
    }
    return res;
  } catch (error) {
    if (error.response && error.response.data.message) {
      let msg = error.response.data.message.split(".");
      message.error(msg[0]);
    } else if (error.request) {
      message.error(error.message);
    } else {
      message.error(error.message);
    }
  }
};

// verify otp
export const verifyOtp = (form) => async (dispatch) => {
  dispatch({ type: VERIFY_OTP_LOADING, payload: null });

  axios.defaults.headers = {
    "Content-Type": "application/json",
  };
  message.info("Loading...");

  try {
    let res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}verify_otp/`,
      form
    );

    if (res?.data?.status === "FAIL") {
      message.error(res?.data?.response);
    } else if (res.status === 200) {
      setItem("companyName", form.get("company"));
      return res;
    }
  } catch (error) {
    if (error.response && error.response.data.message) {
      let msg = error.response.data.message.split(".");
      message.error(msg[0]);
    } else if (error.request) {
      message.error(error.message);
    } else {
      message.error(error.message);
    }
  }
};

export const activateUser = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({ type: ACTIVATE_USER_LOADING, payload: data });

  await message.loading("Saving...", 2);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}activate_user/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    dispatch({ type: ACTIVATE_USER_SUCCESS, payload: result });
    let resData = {
      authToken: result.data.key,
      userId: result.data.user,
      userType: result.data.user_type,
      department: result.data.user_type.department,
      isConsumer: result.data.user_type.is_consumer,
      isProjectManager:
        result.data.user_type.user_type === "Safearth Project Manager",
      isSalesPerson:
        result.data.user_type.user_type === "Safearth Sales Person",
      isInvestor: result.data.user_type.user_type === "Investor",
      tokenExpirationTime: new Date(
        new Date().getTime() + 1000 * 60 * 60
      ).toISOString(),
      credits: result.data.user_type.credits,
      department: result.data.user_type.department,
      activated: true,
    };
    setItem("userData", resData);
    dispatch(authSuccess(resData));
    await message.success("Activation completed successfully!", 2);
    return result;
  } catch (e) {
    message.error("Error occurred while activating user!", 2);
    dispatch({ type: ACTIVATE_USER_FAILURE, payload: e });
    return e;
  }
};

export const activateSubContractor = (data) => async (dispatch) => {
  let userData;
  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({ type: ACTIVATE_USER_LOADING, payload: data });

  await message.loading("Saving...", 2);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}activate_subcontractor/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    dispatch({ type: ACTIVATE_USER_SUCCESS, payload: result.data });

    let resData = {
      authToken: result.data.key,
      userId: result.data.user,
      userType: result.data.user_type,
      department: result.data.user_type.department,
      isConsumer: result.data.user_type.is_consumer,
      isProjectManager:
        result.data.user_type.user_type === "Safearth Project Manager",
      isSalesPerson:
        result.data.user_type.user_type === "Safearth Sales Person",
      isInvestor: result.data.user_type.user_type === "Investor",
      tokenExpirationTime: new Date(
        new Date().getTime() + 1000 * 60 * 60
      ).toISOString(),
      credits: result.data.user_type.credits,
      department: result.data.user_type.department,
      designation: result.data.user_type.designation,
      isCrm: result.data.user_type?.is_crm,
      activated: true,
    };

    setItem("userData", resData);
    dispatch(authSuccess(resData));
    await message.success("Activation completed successfully!", 2);
    return result;
  } catch (e) {
    message.error("Error occurred while activating user!", 2);
    dispatch({ type: ACTIVATE_USER_FAILURE, payload: e });
    return e;
  }
};

export const onBoardUser = (props) => (dispatch) => {
  let customer;

  if (
    props?.location?.search.split("&")[1]?.replace("amp;", "") ==
    "is_subcontractor=true"
  ) {
    setItem("subContractor", true);
  } else if (
    props?.location?.search.split("&")[1]?.replace("amp;", "") ==
    "is_customer=true"
  ) {
    customer = true;
    setItem("customer", true);
  } else {
    setItem("subContractor", false);
    setItem("customer", false);
  }
  axios
    .get(
      "https://pmt.safearth-api.in/api/get_token?" +
        props?.location?.search.split("?")[1]
    )
    .then((result) => {
      if (result.data.status === "FAIL") {
        return message.error(result.data.error);
      } else {
        const token = result?.data?.token;
        setItem("signupToken", token);

        if (token) {
          if (
            customer == true ||
            props?.location?.search.split("&")[1]?.replace("amp;", "") ==
              "is_customer=true"
          ) {
            let userData = {
              authToken: token,
              department: "Customer",
              isConsumer: true,
            };
            setItem("userData", userData);
            dispatch(authSuccess(userData));

            props.history.push("/customer-dashboard");
          } else {
            let userData = {
              authToken: token,
            };
            setItem("userData", userData);
            dispatch(authSuccess(userData));
            props.history.push("/dashboard");
          }
        }
        message.info("Login successfully!");
        return result;
      }
    })
    .catch((err) => {
      message.error(err);
    });
};

export const redirectToProjectDashboard = (props, projectId) => (dispatch) => {
  axios
    .get(
      "https://pmt.safearth-api.in/api/get_token?" +
        props?.location?.search.split("?")[1]
    )
    .then((result) => {
      if (result.data.status === "FAIL") {
        return message.error(result.data.error);
      } else {
        const token = result?.data?.token;
        setItem("signupToken", token);
        let userData = {
          authToken: token,
        };
        setItem("userData", userData);

        dispatch(authSuccess(userData));
        if (token) {
          setItem("projectId", parseInt(projectId));
          props.history.push({
            pathname: "/projects/project-dashboard",
            projectId: parseInt(projectId),
          });
        }
        message.info("Login successfully!");
        return result;
      }
    })
    .catch((err) => {
      message.error("something went wrong");
    });
};

export const onBoardSupplier = (data, props) => (dispatch) => {
  axios
    .post("https://pmt.safearth-api.in/api/onboard_supplier/", data)
    .then((result) => {
      if (result.data === "Success") {
        message.success("Onboarded successfully!");
        clearItems();
        window.location.reload();
        props.history.push("/");

        return result;
      } else {
        message.error(result.data.error);
        return result;
      }
    })
    .catch((err) => {
      message.error("something went wrong");
    });
};

export const addQuote = (data, props) => (dispatch) => {
  axios
    .post("https://pmt.safearth-api.in/api/add_supplier_rfq_quote/", data)
    .then((result) => {
      if (result.data === "Success") {
        message.success("Quote added successfully!");
        clearItems();
        props.history.push("/thankyoupage");
        // window.location.reload();

        return result;
      } else {
        message.error(result.data.error);
        return result;
      }
    })
    .catch((err) => {
      message.error("something went wrong");
    });
};

export const skipTrailNotifications = () => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  axios.defaults.headers = {
    "Content-Type": "application/json",
    Authorization: "Token " + userData.authToken,
  };

  axios
    .post(`${process.env.REACT_APP_BASE_URL}skip_trial_notification/`)
    .then((result) => {
      window.location.reload();
      return result;
      // return message.info("Prompts have been stopped!");
    })
    .catch((err) => {
      return message.error("Something went wrong!");
    });
};

export const onboardVendor = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({ type: ONBOARD_VENDOR_LOADING, payload: data });

  await message.loading("Saving...", 1);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}onboard_vendor/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    if (result?.data?.status === "FAIL") {
      throw result.data;
    }
    dispatch({ type: ONBOARD_VENDOR_SUCCESS, payload: result.data });
    await message.success("Success!", 1);
    return result;
  } catch (e) {
    if (e?.status === "FAIL") {
      message.error(e.response, 2);
    } else {
      message.error("Error occurred while saving changes!", 1);
    }
    dispatch({ type: ONBOARD_VENDOR_FAILURE, payload: e });
    return e;
  }
};

// export const loginUser = (user) => (dispatch) => {

//   dispatch({ type: LOGIN_USER_LOADING, payload: null });
//   axios.defaults.headers = {
//     "Content-Type": "application/json",
//   }
//   axios
//     .post("https://pmt.safearth-api.in/rest-auth/login/", user)
//     .then((result) => {
//       let signupToken = result.data.key;
//       setItem("signupToken", signupToken);
//       dispatch({ type: LOGIN_USER_SUCCESS, payload: result.data });
//     })
//     .catch((e) => {
//       dispatch({ type: LOGIN_USER_FAILURE, payload: { message: e.message } });
//     });
// };
