// AUTH USER DETAILS
export const AUTH_SUCCESS = "AUTH_SUCCESS";

// GET ALL PROJECT
export const GET_ALL_PROJECT_LOADING = "get_all_project_loading";
export const GET_ALL_PROJECT_SUCCESS = "get_all_project_success";
export const GET_ALL_PROJECT_FAILURE = "get_all_project_failure";
export const GET_ALL_PROJECT_RESET = "get_all_project_reset";

// GET PROJECT
export const GET_PROJECT_LOADING = "get_project_loading";
export const GET_PROJECT_SUCCESS = "get_project_success";
export const GET_PROJECT_FAILURE = "get_project_failure";
export const GET_PROJECT_RESET = "get_project_reset";

// POST PROJECT
export const POST_PROJECT_LOADING = "post_project_loading";
export const POST_PROJECT_SUCCESS = "post_project_success";
export const POST_PROJECT_FAILURE = "post_project_failure";
export const POST_PROJECT_RESET = "post_project_reset";

// POST EPC PROJECT
export const POST_EPC_PROJECT_LOADING = "POST_EPC_PROJECT_LOADING";
export const POST_EPC_PROJECT_SUCCESS = "POST_EPC_PROJECT_SUCCESS";
export const POST_EPC_PROJECT_FAILURE = "POST_EPC_PROJECT_FAILURE";
export const POST_EPC_PROJECT_RESET = "POST_EPC_PROJECT_RESET";

// SIGNUP USER
export const SIGNUP_USER_LOADING = "signup_user_loading";
export const SIGNUP_USER_SUCCESS = "signup_user_success";
export const SIGNUP_USER_FAILURE = "signup_user_failure";
export const SIGNUP_USER_RESET = "signup_user_reset";

// IMPORT COMPANY DETAILS
export const GET_COMPANY_DETAILS_LOADING = "GET_COMPANY_DETAILS_LOADING";
export const GET_COMPANY_DETAILS_SUCCESS = "GET_COMPANY_DETAILS_SUCCESS";
export const GET_COMPANY_DETAILS_FAILURE = "GET_COMPANY_DETAILS_FAILURE";

// GET EMAIL CONFIRMATION
export const GET_EMAIL_CONFIRMATION_LOADING = "GET_EMAIL_CONFIRMATION_LOADING";
export const GET_EMAIL_CONFIRMATION_SUCCESS = "GET_EMAIL_CONFIRMATION_SUCCESS";
export const GET_EMAIL_CONFIRMATION_FAILURE = "GET_EMAIL_CONFIRMATION_FAILURE";

// VERIFY OTP
export const VERIFY_OTP_LOADING = "VERIFY_OTP_LOADING";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";

// LOGIN USER
export const LOGIN_USER_LOADING = "login_user_loading";
export const LOGIN_USER_SUCCESS = "login_user_success";
export const LOGIN_USER_FAILURE = "login_user_failure";
export const LOGIN_USER_RESET = "login_user_reset";

// ACTIVATE USER
export const ACTIVATE_USER_LOADING = "ACTIVATE_USER_LOADING";
export const ACTIVATE_USER_SUCCESS = "ACTIVATE_USER_SUCCESS";
export const ACTIVATE_USER_FAILURE = "ACTIVATE_USER_FAILURE";
export const ACTIVATE_USER_RESET = "ACTIVATE_USER_RESET";

// GET DOCS
export const GET_DOCS_LOADING = "get_docs_loading";
export const GET_DOCS_SUCCESS = "get_docs_success";
export const GET_DOCS_FAILURE = "get_docs_failure";
export const GET_DOCS_RESET = "get_docs_reset";

// GET DOCS LIST
export const GET_DOCS_LIST_LOADING = "GET_DOCS_LIST_LOADING";
export const GET_DOCS_LIST_SUCCESS = "GET_DOCS_LIST_SUCCESS";
export const GET_DOCS_LIST_FAILURE = "GET_DOCS_LIST_FAILURE";
export const GET_DOCS_LIST_RESET = "GET_DOCS_LIST_RESET";

// POST DOCS
export const POST_DOCS_LOADING = "POST_DOCS_LOADING";
export const POST_DOCS_SUCCESS = "POST_DOCS_SUCCESS";
export const POST_DOCS_FAILURE = "POST_DOCS_FAILURE";
export const POST_DOCS_RESET = "POST_DOCS_RESET";

// GET PHOTOS
export const GET_PHOTOS_LOADING = "GET_PHOTOS_LOADING";
export const GET_PHOTOS_SUCCESS = "GET_PHOTOS_SUCCESS";
export const GET_PHOTOS_FAILURE = "GET_PHOTOS_FAILURE";
export const GET_PHOTOS_RESET = "GET_PHOTOS_RESET";

// GET PHOTO ALBUM
export const GET_PHOTO_ALBUM_LOADING = "GET_PHOTO_ALBUM_LOADING";
export const GET_PHOTO_ALBUM_SUCCESS = "GET_PHOTO_ALBUM_SUCCESS";
export const GET_PHOTO_ALBUM_FAILURE = "GET_PHOTO_ALBUM_FAILURE";
export const GET_PHOTO_ALBUM_RESET = "GET_PHOTO_ALBUM_RESET";

// POST PHOTOS
export const POST_PHOTOS_LOADING = "POST_PHOTOS_LOADING";
export const POST_PHOTOS_SUCCESS = "POST_PHOTOS_SUCCESS";
export const POST_PHOTOS_FAILURE = "POST_PHOTOS_FAILURE";
export const POST_PHOTOS_RESET = "POST_PHOTOS_RESET";

// GET PROJECT PHOTOS
export const GET_PROJECT_PHOTOS_LOADING = "GET_PROJECT_PHOTOS_LOADING";
export const GET_PROJECT_PHOTOS_SUCCESS = "GET_PROJECT_PHOTOS_SUCCESS";
export const GET_PROJECT_PHOTOS_FAILURE = "GET_PROJECT_PHOTOS_FAILURE";
export const GET_PROJECT_PHOTOS_RESET = "GET_PROJECT_PHOTOS_RESET";

// POST IMAGE TITLE AND DESCRIPTION
export const POST_IMAGE_TITLE_AND_DESCRIPTION_LOADING =
  "POST_IMAGE_TITLE_AND_DESCRIPTION_LOADING";
export const POST_IMAGE_TITLE_AND_DESCRIPTION_SUCCESS =
  "POST_IMAGE_TITLE_AND_DESCRIPTION_SUCCESS";
export const POST_IMAGE_TITLE_AND_DESCRIPTION_FAILURE =
  "POST_IMAGE_TITLE_AND_DESCRIPTION_FAILURE";
export const POST_IMAGE_TITLE_AND_DESCRIPTION_RESET =
  "POST_IMAGE_TITLE_AND_DESCRIPTION_RESET";

// GET VENDOR PHOTOS DETAIL
export const GET_VENDOR_PHOTOS_DETAIL_LOADING =
  "GET_VENDOR_PHOTOS_DETAIL_LOADING";
export const GET_VENDOR_PHOTOS_DETAIL_SUCCESS =
  "GET_VENDOR_PHOTOS_DETAIL_SUCCESS";
export const GET_VENDOR_PHOTOS_DETAIL_FAILURE =
  "GET_VENDOR_PHOTOS_DETAIL_FAILURE";
export const GET_VENDOR_PHOTOS_DETAIL_RESET = "GET_VENDOR_PHOTOS_DETAIL_RESET";

// GET VENDOR STAGE PHOTOS
export const GET_VENDOR_STAGE_PHOTOS_LOADING =
  "GET_VENDOR_STAGE_PHOTOS_LOADING";
export const GET_VENDOR_STAGE_PHOTOS_SUCCESS =
  "GET_VENDOR_STAGE_PHOTOS_SUCCESS";
export const GET_VENDOR_STAGE_PHOTOS_FAILURE =
  "GET_VENDOR_STAGE_PHOTOS_FAILURE";
export const GET_VENDOR_STAGE_PHOTOS_RESET = "GET_VENDOR_STAGE_PHOTOS_RESET";

// GET TEAMS
export const GET_TEAMS_LOADING = "get_teams_loading";
export const GET_TEAMS_SUCCESS = "get_teams_success";
export const GET_TEAMS_FAILURE = "get_teams_failure";
export const GET_TEAMS_RESET = "get_teams_reset";

// GET SUPPLIERS
export const GET_SUPPLIERS_SUCCESS = "get_suppliers_success";
export const GET_SUPPLIERS_FAILURE = "get_suppliers_failure";
export const GET_SUPPLIERS_LOADING = "get_suppliers_loading";

// GET GANTTS
export const GET_GANTTS_LOADING = "get_gantts_loading";
export const GET_GANTTS_SUCCESS = "get_gantts_success";
export const GET_GANTTS_FAILURE = "get_gantts_failure";
export const GET_GANTTS_RESET = "get_gantts_reset";

// GET PREREQUISITS
export const GET_PREREQUISITS_LOADING = "get_prerequisits_loading";
export const GET_PREREQUISITS_SUCCESS = "get_prerequisits_success";
export const GET_PREREQUISITS_FAILURE = "get_prerequisits_failure";
export const GET_PREREQUISITS_RESET = "get_prerequisits_reset";

// GET APPROVAL
export const GET_APPROVAL_LOADING = "get_approval_loading";
export const GET_APPROVAL_SUCCESS = "get_approval_success";
export const GET_APPROVAL_FAILURE = "get_approval_failure";
export const GET_APPROVAL_RESET = "get_approval_reset";

// GET ENGINEERING
export const GET_ENGINEERING_LOADING = "get_engineering_loading";
export const GET_ENGINEERING_SUCCESS = "get_engineering_success";
export const GET_ENGINEERING_FAILURE = "get_engineering_failure";
export const GET_ENGINEERING_RESET = "get_engineering_reset";

// GET PROCUREMENT
export const GET_PROCUREMENT_LOADING = "get_procurement_loading";
export const GET_PROCUREMENT_SUCCESS = "get_procurement_success";
export const GET_PROCUREMENT_FAILURE = "get_procurement_failure";
export const GET_PROCUREMENT_RESET = "get_procurement_reset";

// GET MATERIAL HANDLING
export const GET_MATERIAL_HANDLING_LOADING = "get_material_handling_loading";
export const GET_MATERIAL_HANDLING_SUCCESS = "get_material_handling_success";
export const GET_MATERIAL_HANDLING_FAILURE = "get_material_handling_failure";
export const GET_MATERIAL_HANDLING_RESET = "get_material_handling_reset";

// GET CONSTRUCTION
export const GET_CONSTRUCTION_LOADING = "get_construction_loading";
export const GET_CONSTRUCTION_SUCCESS = "get_construction_success";
export const GET_CONSTRUCTION_FAILURE = "get_construction_failure";
export const GET_CONSTRUCTION_RESET = "get_construction_reset";

// GET SITE HANDOVER
export const GET_SITE_HANDOVER_LOADING = "get_site_handover_loading";
export const GET_SITE_HANDOVER_SUCCESS = "get_site_handover_success";
export const GET_SITE_HANDOVER_FAILURE = "get_site_handover_failure";
export const GET_SITE_HANDOVER_RESET = "get_site_handover_reset";

// GET GANTT EVENTS
export const GET_GANTT_EVENTS_LOADING = "get_gantt_events_loading";
export const GET_GANTT_EVENTS_SUCCESS = "get_gantt_events_success";
export const GET_GANTT_EVENTS_FAILURE = "get_gantt_events_failure";
export const GET_GANTT_EVENTS_RESET = "get_gantt_events_reset";

// GET GANTT EVENTS MENU
export const GET_GANTT_EVENTS_MENU_LOADING = "get_gantt_events_menu_loading";
export const GET_GANTT_EVENTS_MENU_SUCCESS = "get_gantt_events_menu_success";
export const GET_GANTT_EVENTS_MENU_FAILURE = "get_gantt_events_menu_failure";
export const GET_GANTT_EVENTS_MENU_RESET = "get_gantt_events_menu_reset";

// GET GANTT EVENT STAGE
export const GET_GANTT_EVENT_STAGE_LOADING = "get_gantt_event_stage_loading";
export const GET_GANTT_EVENT_STAGE_SUCCESS = "get_gantt_event_stage_success";
export const GET_GANTT_EVENT_STAGE_FAILURE = "get_gantt_event_stage_failure";
export const GET_GANTT_EVENT_STAGE_RESET = "get_gantt_event_stage_reset";
export const GET_GANTT_EVENT_STAGE_INITIAL_VALUES =
  "get_gantt_event_stage_imitial_values";
export const UPDATE_GANTT_EVENT_STAGE = "UPDATE_GANTT_EVENT_STAGE";

// GET STAGE OVERVIEW
export const GET_STAGE_OVERVIEW_LOADING = "GET_STAGE_OVERVIEW_LOADING";
export const GET_STAGE_OVERVIEW_SUCCESS = "GET_STAGE_OVERVIEW_SUCCESS";
export const GET_STAGE_OVERVIEW_FAILURE = "GET_STAGE_OVERVIEW_FAILURE";
export const GET_STAGE_OVERVIEW_RESET = "GET_STAGE_OVERVIEW_RESET";

export const UPDATE_STAGE_OVERVIEW = "UPDATE_STAGE_OVERVIEW";

// GET EVENT OVERVIEW
export const GET_EVENT_OVERVIEW_LOADING = "GET_EVENT_OVERVIEW_LOADING";
export const GET_EVENT_OVERVIEW_SUCCESS = "GET_EVENT_OVERVIEW_SUCCESS";
export const GET_EVENT_OVERVIEW_FAILURE = "GET_EVENT_OVERVIEW_FAILURE";
export const GET_EVENT_OVERVIEW_RESET = "GET_EVENT_OVERVIEW_RESET";

// GET EVENT DATA
export const GET_EVENT_DATA_LOADING = "get_event_data_loading";
export const GET_EVENT_DATA_SUCCESS = "get_event_data_success";
export const GET_EVENT_DATA_FAILURE = "get_event_data_failure";
export const GET_EVENT_DATA_RESET = "get_event_data_reset";
export const GET_EVENT_INITIAL_VALUES = "get_event_initial_values";

//DELETE OVERVIEW DOC
export const DELETE_OVERVIEW_DOC_LOADING = "DELETE_OVERVIEW_DOC_LOADING";
export const DELETE_OVERVIEW_DOC_SUCCESS = "DELETE_OVERVIEW_DOC_SUCCESS";
export const DELETE_OVERVIEW_DOC_FAILURE = "DELETE_OVERVIEW_DOC_FAILURE";
export const DELETE_OVERVIEW_DOC_RESET = "DELETE_OVERVIEW_DOC_RESET";

// GET COMMENT DATA
export const GET_COMMENT_LOADING = "get_comment_loading";
export const GET_COMMENT_SUCCESS = "get_comment_success";
export const GET_COMMENT_FAILURE = "get_comment_failure";
export const GET_COMMENT_RESET = "get_comment_reset";

// POST COMMENT DATA
export const POST_COMMENT_LOADING = "post_comment_loading";
export const POST_COMMENT_SUCCESS = "post_comment_success";
export const POST_COMMENT_FAILURE = "post_comment_failure";
export const POST_COMMENT_RESET = "post_comment_reset";

// GET COMMENT FILE DATA
export const GET_COMMENT_FILE_LOADING = "get_file_comment_loading";
export const GET_COMMENT_FILE_SUCCESS = "get_file_comment_success";
export const GET_COMMENT_FILE_FAILURE = "get_file_comment_failure";
export const GET_COMMENT_FILE_RESET = "get_file_comment_reset";

// GET DAILY UPDATE PROJECTS
export const GET_DAILY_UPDATE_PROJECTS_LOADING =
  "GET_DAILY_UPDATE_PROJECTS_LOADING";
export const GET_DAILY_UPDATE_PROJECTS_SUCCESS =
  "GET_DAILY_UPDATE_PROJECTS_SUCCESS";
export const GET_DAILY_UPDATE_PROJECTS_FAILURE =
  "GET_DAILY_UPDATE_PROJECTS_FAILURE";

// GET TODAY'S TASKS FOR DAILY UPDATES
export const GET_TODAY_TASKS_LOADING = "GET_TODAY_TASKS_LOADING";
export const GET_TODAY_TASKS_SUCCESS = "GET_TODAY_TASKS_SUCCESS";
export const GET_TODAY_TASKS_FAILURE = "GET_TODAY_TASKS_FAILURE";

// GET TOMORROW'S TASKS FOR DAILY UPDATES
export const GET_TOMORROW_TASKS_LOADING = "GET_TOMORROW_TASKS_LOADING";
export const GET_TOMORROW_TASKS_SUCCESS = "GET_TOMORROW_TASKS_SUCCESS";
export const GET_TOMORROW_TASKS_FAILURE = "GET_TOMORROW_TASKS_FAILURE";

// POST DAILY UPDATE
export const POST_DAILY_UPDATE_LOADING = "POST_DAILY_UPDATE_LOADING";
export const POST_DAILY_UPDATE_SUCCESS = "POST_DAILY_UPDATE_SUCCESS";
export const POST_DAILY_UPDATE_FAILURE = "POST_DAILY_UPDATE_FAILURE";

// GET EVENTS
export const GET_EVENTS_LOADING = "GET_EVENTS_LOADING";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAILURE = "GET_EVENTS_FAILURE";
export const GET_EVENTS_RESET = "GET_EVENTS_RESET";

// GET DETAILS OF A PARTICULAR PROJECT
export const GET_SPECIFIC_PROJECT_DETAILS_LOADING =
  "GET_SPECIFIC_PROJECT_DETAILS_LOADING";
export const GET_SPECIFIC_PROJECT_DETAILS_SUCCESS =
  "GET_SPECIFIC_PROJECT_DETAILS_SUCCESS";
export const GET_SPECIFIC_PROJECT_DETAILS_FAILURE =
  "GET_SPECIFIC_PROJECT_DETAILS_FAILURE";

// ADD TEAM MEMBERS
export const POST_TEAM_DETAILS_LOADING = "POST_TEAM_DETAILS_LOADING";
export const POST_TEAM_DETAILS_SUCCESS = "POST_TEAM_DETAILS_SUCCESS";
export const POST_TEAM_DETAILS_FAILURE = "POST_TEAM_DETAILS_FAILURE";

// REMOVE TEAM MEMBERS
export const POST_MEMBERS_LIST_LOADING = "POST_MEMBERS_LIST_LOADING";
export const POST_MEMBERS_LIST_SUCCESS = "POST_MEMBERS_LIST_SUCCESS";
export const POST_MEMBERS_LIST_FAILURE = "POST_MEMBERS_LIST_FAILURE";

// GET DELETE
export const GET_DELETE_LOADING = "get_delete_loading";
export const GET_DELETE_SUCCESS = "get_delete_success";
export const GET_DELETE_FAILURE = "get_delete_failure";
export const GET_DELETE_RESET = "get_delete_reset";

// GET UPDATE
export const GET_UPDATE_LOADING = "get_update_loading";
export const GET_UPDATE_SUCCESS = "get_update_success";
export const GET_UPDATE_FAILURE = "get_update_failure";
export const GET_UPDATE_RESET = "get_update_reset";

// GET VIEW SPECIFICATION
export const GET_VIEW_SPECIFICATION_LOADING = "get_view_specification_loading";
export const GET_VIEW_SPECIFICATION_SUCCESS = "get_view_specification_success";
export const GET_VIEW_SPECIFICATION_FAILURE = "get_view_specification_failure";
export const GET_VIEW_SPECIFICATION_RESET = "get_view_specification_reset";

// GET TICKETS
export const GET_TICKET_LOADING = "get_ticket_loading";
export const GET_TICKET_SUCCESS = "get_ticket_success";
export const GET_TICKET_FAILURE = "get_ticket_failure";
export const GET_TICKET_RESET = "get_ticket_reset";

// GET SPOC
export const GET_SPOC_LOADING = "get_spoc_loading";
export const GET_SPOC_SUCCESS = "get_spoc_success";
export const GET_SPOC_FAILURE = "get_spoc_failure";
export const GET_SPOC_RESET = "get_spoc_reset";

// GET PROJECT LEVEL TEAMS
export const GET_PROJECT_LEVEL_TEAMS_LOADING =
  "GET_PROJECT_LEVEL_TEAMS_LOADING";
export const GET_PROJECT_LEVEL_TEAMS_SUCCESS =
  "GET_PROJECT_LEVEL_TEAMS_SUCCESS";
export const GET_PROJECT_LEVEL_TEAMS_FAILURE =
  "GET_PROJECT_LEVEL_TEAMS_FAILURE";

// GET TEAM MEMBERS' NAME
export const GET_TEAM_MEMBERS_NAME_LOADING = "GET_TEAM_MEMBERS_NAME_LOADING";
export const GET_TEAM_MEMBERS_NAME_SUCCESS = "GET_TEAM_MEMBERS_NAME_SUCCESS";
export const GET_TEAM_MEMBERS_NAME_FAILURE = "GET_TEAM_MEMBERS_NAME_FAILURE";

// ADD TEAM MEMBER AT PROJECT LEVEL TEAM
export const POST_TEAM_MEMBER_DETAILS_LOADING =
  "POST_TEAM_MEMBER_DETAILS_LOADING";
export const POST_TEAM_MEMBER_DETAILS_SUCCESS =
  "POST_TEAM_MEMBER_DETAILS_SUCCESS";
export const POST_TEAM_MEMBER_DETAILS_FAILURE =
  "POST_TEAM_MEMBER_DETAILS_FAILURE";

// CREATE NEW RIGHT FOR A TEAM MEMBER
export const CREATE_NEW_RIGHT_LOADING = "CREATE_NEW_RIGHT_LOADING";
export const CREATE_NEW_RIGHT_SUCCESS = "CREATE_NEW_RIGHT_SUCCESS";
export const CREATE_NEW_RIGHT_FAILURE = "CREATE_NEW_RIGHT_FAILURE";

// GET SPOC
export const GET_UPLOAD_DOCS_LOADING = "get_upload_docs_loading";
export const GET_UPLOAD_DOCS_SUCCESS = "get_upload_docs_success";
export const GET_UPLOAD_DOCS_FAILURE = "get_upload_docs_failure";
export const GET_UPLOAD_DOCS_RESET = "get_upload_docs_reset";

// GET GET EVENT PHOTOS
export const GET_EVENT_PHOTOS_LOADING = "GET_EVENT_PHOTOS_LOADING";
export const GET_EVENT_PHOTOS_SUCCESS = "GET_EVENT_PHOTOS_SUCCESS";
export const GET_EVENT_PHOTOS_FAILURE = "GET_EVENT_PHOTOS_FAILURE";
export const GET_EVENT_PHOTOS_RESET = "GET_EVENT_PHOTOS_RESET";

// GET GANTT DATA
export const GET_GANTT_DATA_LOADING = "get_gantt_data_loading";
export const GET_GANTT_DATA_SUCCESS = "get_gantt_data_success";
export const GET_GANTT_DATA_FAILURE = "get_gantt_data_failure";
export const GET_GANTT_DATA_RESET = "get_gantt_data_reset";

// GET ALL EVENTS,
export const GET_ALL_EVENT_LOADING = "get_all_event_loading";
export const GET_ALL_EVENT_SUCCESS = "get_all_event_success";
export const GET_ALL_EVENT_FAILURE = "get_all_event_failure";

// GET NOTIFICATION
export const GET_NOTIFICATION_LOADING = "get_notification_loading";
export const GET_NOTIFICATION_SUCCESS = "get_notification_success";
export const GET_NOTIFICATION_FAILURE = "get_notification_failure";
export const GET_NOTIFICATION_RESET = "get_notification_reset";

// POST EVENT DELAY REASON
export const POST_EVENT_DELAY_REASON_LOADING =
  "POST_EVENT_DELAY_REASON_LOADING";
export const POST_EVENT_DELAY_REASON_SUCCESS =
  "POST_EVENT_DELAY_REASON_SUCCESS";
export const POST_EVENT_DELAY_REASON_FAILURE =
  "POST_EVENT_DELAY_REASON_FAILURE";
export const POST_EVENT_DELAY_REASON_RESET = "POST_EVENT_DELAY_REASON_RESET";

// CHANGE NOTIFICATION VISIBILITY
export const CHANGE_NOTIFICATION_VISIBILITY_LOADING =
  "CHANGE_NOTIFICATION_VISIBILITY_LOADING";
export const CHANGE_NOTIFICATION_VISIBILITY_SUCCESS =
  "CHANGE_NOTIFICATION_VISIBILITY_SUCCESS";
export const CHANGE_NOTIFICATION_VISIBILITY_FAILURE =
  "CHANGE_NOTIFICATION_VISIBILITY_FAILURE";
export const CHANGE_NOTIFICATION_VISIBILITY_RESET =
  "CHANGE_NOTIFICATION_VISIBILITY_RESET";

// GET EVENT DELAYED INFO
export const GET_EVENT_DELAYED_INFO_LOADING = "GET_EVENT_DELAYED_INFO_LOADING";
export const GET_EVENT_DELAYED_INFO_SUCCESS = "GET_EVENT_DELAYED_INFO_SUCCESS";
export const GET_EVENT_DELAYED_INFO_FAILURE = "GET_EVENT_DELAYED_INFO_FAILURE";
export const GET_EVENT_DELAYED_INFO_RESET = "GET_EVENT_DELAYED_INFO_RESET";

// GET INVENTORY
export const GET_INVENTORY_LOADING = "GET_INVENTORY_LOADING";
export const GET_INVENTORY_SUCCESS = "GET_INVENTORY_SUCCESS";
export const GET_INVENTORY_FAILURE = "GET_INVENTORY_FAILURE";

// GET PROJECT INVENTORY
export const GET_PROJECT_INVENTORY_LOADING = "GET_PROJECT_INVENTORY_LOADING";
export const GET_PROJECT_INVENTORY_SUCCESS = "GET_PROJECT_INVENTORY_SUCCESS";
export const GET_PROJECT_INVENTORY_FAILURE = "GET_PROJECT_INVENTORY_FAILURE";

// ADD MATERIAL
export const ADD_MATERIAL_LOADING = "ADD_MATERIAL_LOADING";
export const ADD_MATERIAL_SUCCESS = "ADD_MATERIAL_SUCCESS";
export const ADD_MATERIAL_FAILURE = "ADD_MATERIAL_FAILURE";

// REPORT WASTAGE
export const REPORT_WASTAGE_LOADING = "REPORT_WASTAGE_LOADING";
export const REPORT_WASTAGE_SUCCESS = "REPORT_WASTAGE_SUCCESS";
export const REPORT_WASTAGE_FAILURE = "REPORT_WASTAGE_FAILURE";

// ASSIGN MATERIAL
export const ASSIGN_MATERIAL_LOADING = "ASSIGN_MATERIAL_LOADING";
export const ASSIGN_MATERIAL_SUCCESS = "ASSIGN_MATERIAL_SUCCESS";
export const ASSIGN_MATERIAL_FAILURE = "ASSIGN_MATERIAL_FAILURE";

// GET PROJECT DOCUMENTS
export const GET_PROJECT_DOCS_LOADING = "GET_PROJECT_DOCS_LOADING";
export const GET_PROJECT_DOCS_SUCCESS = "GET_PROJECT_DOCS_SUCCESS";
export const GET_PROJECT_DOCS_FAILURE = "GET_PROJECT_DOCS_FAILURE";

// GET VENDOR PROJECT RELATED DOCUMENTS
export const GET_VENDOR_DOC_DETAILS_LOADING = "GET_VENDOR_DOC_DETAILS_LOADING";
export const GET_VENDOR_DOC_DETAILS_SUCCESS = "GET_VENDOR_DOC_DETAILS_SUCCESS";
export const GET_VENDOR_DOC_DETAILS_FAILURE = "GET_VENDOR_DOC_DETAILS_FAILURE";

// GET PROJECT DOCUMENTS
export const GET_DRAWING_LOADING = "get_drawing_loading";
export const GET_DRAWING_SUCCESS = "get_drawing_success";
export const GET_DRAWING_FAILURE = "get_drawing_failure";
// CHANGE DEPARTMENT
export const CHANGE_DEPARTMENT_LOADING = "CHANGE_DEPARTMENT_LOADING";
export const CHANGE_DEPARTMENT_SUCCESS = "CHANGE_DEPARTMENT_SUCCESS";
export const CHANGE_DEPARTMENT_FAILURE = "CHANGE_DEPARTMENT_FAILURE";

// GET RESPONSIBILITY MATRIX
export const GET_RESPONSIBILITY_MATRIX_LOADING =
  "GET_RESPONSIBILITY_MATRIX_LOADING";
export const GET_RESPONSIBILITY_MATRIX_SUCCESS =
  "GET_RESPONSIBILITY_MATRIX_SUCCESS";
export const GET_RESPONSIBILITY_MATRIX_FAILURE =
  "GET_RESPONSIBILITY_MATRIX_FAILURE";

// GET COMPANY PROFILE
export const GET_PROFILE_LOADING = "GET_PROFILE_LOADING";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

// GET STAKEHOLDER PROFILE
export const GET_STAKEHOLDER_PROFILE_LOADING =
  "GET_STAKEHOLDER_PROFILE_LOADING";
export const GET_STAKEHOLDER_PROFILE_SUCCESS =
  "GET_STAKEHOLDER_PROFILE_SUCCESS";
export const GET_STAKEHOLDER_PROFILE_FAILURE =
  "GET_STAKEHOLDER_PROFILE_FAILURE";

// ADD SECONDARY EMAIL
export const ADD_SECONDARY_EMAIL_LOADING = "ADD_SECONDARY_EMAIL_LOADING";
export const ADD_SECONDARY_EMAIL_SUCCESS = "ADD_SECONDARY_EMAIL_SUCCESS";
export const ADD_SECONDARY_EMAIL_FAILURE = "ADD_SECONDARY_EMAIL_FAILURE";
export const ADD_SECONDARY_EMAIL_RESET = "ADD_SECONDARY_EMAIL_RESET";

// REMOVE_SECONDARY_EMAIL
export const REMOVE_SECONDARY_EMAIL_LOADING = "REMOVE_SECONDARY_EMAIL_LOADING";
export const REMOVE_SECONDARY_EMAIL_SUCCESS = "REMOVE_SECONDARY_EMAIL_SUCCESS";
export const REMOVE_SECONDARY_EMAIL_FAILURE = "REMOVE_SECONDARY_EMAIL_FAILURE";
export const REMOVE_SECONDARY_EMAIL_RESET = "REMOVE_SECONDARY_EMAIL_RESET";

// GET SAMPLE GANTTS
export const GET_SAMPLE_GANTTS_LOADING = "GET_SAMPLE_GANTTS_LOADING";
export const GET_SAMPLE_GANTTS_SUCCESS = "GET_SAMPLE_GANTTS_SUCCESS";
export const GET_SAMPLE_GANTTS_FAILURE = "GET_SAMPLE_GANTTS_FAILURE";
export const GET_SAMPLE_GANTTS_RESET = "GET_SAMPLE_GANTTS_RESET";

// GET SAMPLE GANTT DETAILS
export const GET_SAMPLE_GANTT_DETAILS_LOADING =
  "GET_SAMPLE_GANTT_DETAILS_LOADING";
export const GET_SAMPLE_GANTT_DETAILS_SUCCESS =
  "GET_SAMPLE_GANTT_DETAILS_SUCCESS";
export const GET_SAMPLE_GANTTS_DETAILS_FAILURE =
  "GET_SAMPLE_GANTTS_DETAILS_FAILURE";
export const GET_SAMPLE_GANTTS_DETAILS_RESET =
  "GET_SAMPLE_GANTTS_DETAILS_RESET";

// GET SAMPLE GANTT PROJECT DETAILS
export const GET_SAMPLE_GANTTS_PROJECT_DETAILS_LOADING =
  "GET_SAMPLE_GANTTS_PROJECT_DETAILS_LOADING";
export const GET_SAMPLE_GANTTS_PROJECT_DETAILS_SUCCESS =
  "GET_SAMPLE_GANTTS_PROJECT_DETAILS_SUCCESS";
export const GET_SAMPLE_GANTTS_PROJECT_DETAILS_FAILURE =
  "GET_SAMPLE_GANTTS_PROJECT_DETAILS_FAILURE";

// GET GANTT PROJECT DETAILS
export const GET_GANTTS_PROJECT_DETAILS_LOADING =
  "GET_GANTTS_PROJECT_DETAILS_LOADING";
export const GET_GANTTS_PROJECT_DETAILS_SUCCESS =
  "GET_GANTTS_PROJECT_DETAILS_SUCCESS";
export const GET_GANTTS_PROJECT_DETAILS_FAILURE =
  "GET_GANTTS_PROJECT_DETAILS_FAILURE";
// GET BUDGETED FINANCE DETAILS
export const GET_BUDGETED_FINANCE_DETAILS_LOADING =
  "GET_BUDGETED_FINANCE_DETAILS_LOADING";
export const GET_BUDGETED_FINANCE_DETAILS_SUCCESS =
  "GET_BUDGETED_FINANCE_DETAILS_SUCCESS";
export const GET_BUDGETED_FINANCE_DETAILS_FAILURE =
  "GET_BUDGETED_FINANCE_DETAILS_FAILURE";

// GET CASHFLOW
export const GET_CASHFLOW_LOADING = "GET_CASHFLOW_LOADING";
export const GET_CASHFLOW_SUCCESS = "GET_CASHFLOW_SUCCESS";
export const GET_CASHFLOW_FAILURE = "GET_CASHFLOW_FAILURE";

// GET ACTUAL FINANCE DETAILS
export const GET_ACTUAL_FINANCE_DETAILS_LOADING =
  "GET_ACTUAL_FINANCE_DETAILS_LOADING";
export const GET_ACTUAL_FINANCE_DETAILS_SUCCESS =
  "GET_ACTUAL_FINANCE_DETAILS_SUCCESS";
export const GET_ACTUAL_FINANCE_DETAILS_FAILURE =
  "GET_ACTUAL_FINANCE_DETAILS_FAILURE";

// GET MILESTONES
export const GET_MILESTONES_LOADING = "GET_MILESTONES_LOADING";
export const GET_MILESTONES_SUCCESS = "GET_MILESTONES_SUCCESS";
export const GET_MILESTONES_FAILURE = "GET_MILESTONES_FAILURE";

// POST FINANCE FILE
export const POST_FINANCE_FILE_LOADING = "POST_FINANCE_FILE_LOADING";
export const POST_FINANCE_FILE_SUCCESS = "POST_FINANCE_FILE_SUCCESS";
export const POST_FINANCE_FILE_FAILURE = "POST_FINANCE_FILE_FAILURE";

// CALCULATE P&L
export const CALCULATE_P_N_L_LOADING = "CALCULATE_P_N_L_LOADING";
export const CALCULATE_P_N_L_SUCCESS = "CALCULATE_P_N_L_SUCCESS";
export const CALCULATE_P_N_L_FAILURE = "CALCULATE_P_N_L_FAILURE";

// UPDATE BUDGETED FINANCE DATA
export const UPDATE_BUDGETED_DATA_LOADING = "UPDATE_BUDGETED_DATA_LOADING";
export const UPDATE_BUDGETED_DATA_SUCCESS = "UPDATE_BUDGETED_DATA_SUCCESS";
export const UPDATE_BUDGETED_DATA_FAILURE = "UPDATE_BUDGETED_DATA_FAILURE";

// CREATE FINANCIAL PLAN
export const CREATE_FINANCIAL_PLAN_LOADING = "CREATE_FINANCIAL_PLAN_LOADING";
export const CREATE_FINANCIAL_PLAN_SUCCESS = "CREATE_FINANCIAL_PLAN_SUCCESS";
export const CREATE_FINANCIAL_PLAN_FAILURE = "CREATE_FINANCIAL_PLAN_FAILURE";
export const CREATE_FINANCIAL_PLAN_RESET = "CREATE_FINANCIAL_PLAN_RESET";

// GET FINANCIAL PLAN
export const GET_FINANCIAL_PLAN_LOADING = "GET_FINANCIAL_PLAN_LOADING";
export const GET_FINANCIAL_PLAN_SUCCESS = "GET_FINANCIAL_PLAN_SUCCESS";
export const GET_FINANCIAL_PLAN_FAILURE = "GET_FINANCIAL_PLAN_FAILURE";
export const GET_FINANCIAL_PLAN_RESET = "GET_FINANCIAL_PLAN_RESET";

// DOWNLOAD BUDGET
export const DOWNLOAD_BUDGET_LOADING = "DOWNLOAD_BUDGET_LOADING";
export const DOWNLOAD_BUDGET_SUCCESS = "DOWNLOAD_BUDGET_SUCCESS";
export const DOWNLOAD_BUDGET_FAILURE = "DOWNLOAD_BUDGET_FAILURE";

// UPLOAD PROJECT BUDGET
export const UPLOAD_PROJECT_BUDGET_LOADING = "UPLOAD_PROJECT_BUDGET_LOADING";
export const UPLOAD_PROJECT_BUDGET_SUCCESS = "UPLOAD_PROJECT_BUDGET_SUCCESS";
export const UPLOAD_PROJECT_BUDGET_FAILURE = "UPLOAD_PROJECT_BUDGET_FAILURE";

// ALL SAMPLE EVENTS
export const GET_ALL_SAMPLE_EVENT_LOADING = "GET_ALL_SAMPLE_EVENT_LOADING";
export const GET_ALL_SAMPLE_EVENT_SUCCESS = "GET_ALL_SAMPLE_EVENT_SUCCESS";
export const GET_ALL_SAMPLE_EVENT_FAILURE = "GET_ALL_SAMPLE_EVENT_FAILURE";

// SAMPLE EVENT
export const GET_SAMPLE_EVENT_LOADING = "GET_SAMPLE_EVENT_LOADING";
export const GET_SAMPLE_EVENT_SUCCESS = "GET_SAMPLE_EVENT_SUCCESS";
export const GET_SAMPLE_EVENT_FAILURE = "GET_SAMPLE_EVENT_FAILURE";

// SAMPLE EVENTS
export const POST_SAMPLE_EVENT_LOADING = "POST_SAMPLE_EVENT_LOADING";
export const POST_SAMPLE_EVENT_SUCCESS = "POST_SAMPLE_EVENT_SUCCESS";
export const POST_SAMPLE_EVENT_FAILURE = "POST_SAMPLE_EVENT_FAILURE";
export const POST_SAMPLE_EVENT_RESET = "POST_SAMPLE_EVENT_RESET";

// SAMPLE EVENTS
export const GET_PROJECT_SPECIFICATION_LOADING =
  "GET_PROJECT_SPECIFICATION_LOADING";
export const GET_PROJECT_SPECIFICATION_SUCCESS =
  "GET_PROJECT_SPECIFICATION_SUCCESS";
export const GET_PROJECT_SPECIFICATION_FAILURE =
  "GET_PROJECT_SPECIFICATION_FAILURE";
export const GET_PROJECT_SPECIFICATION_RESET =
  "GET_PROJECT_SPECIFICATION_RESET";

// SAMPLE EVENTS
export const GET_PROJECT_SPECIFICATION_EVENTS_LOADING =
  "GET_PROJECT_SPECIFICATION_EVENTS_LOADING";
export const GET_PROJECT_SPECIFICATION_EVENTS_SUCCESS =
  "GET_PROJECT_SPECIFICATION_EVENTS_SUCCESS";
export const GET_PROJECT_SPECIFICATION_EVENTS_FAILURE =
  "GET_PROJECT_SPECIFICATION_EVENTS_FAILURE";
export const GET_PROJECT_SPECIFICATION_EVENTS_RESET =
  "GET_PROJECT_SPECIFICATION_EVENTS_RESET";

// SAMPLE EVENTS
export const GET_SUB_CONTRACTOR_LOADING = "GET_SUB_CONTRACTOR_LOADING";
export const GET_SUB_CONTRACTOR_SUCCESS = "GET_SUB_CONTRACTOR_SUCCESS";
export const GET_SUB_CONTRACTOR_FAILURE = "GET_SUB_CONTRACTOR_FAILURE";
export const GET_SUB_CONTRACTOR_RESET = "GET_SUB_CONTRACTOR_RESET";

// SUBCONTRACTOR STAGE ACCESS
export const GET_SUB_CONTRACTOR_STAGE_ACCESS_LOADING =
  "GET_SUB_CONTRACTOR_STAGE_ACCESS_LOADING";
export const GET_SUB_CONTRACTOR_STAGE_ACCESS_SUCCESS =
  "GET_SUB_CONTRACTOR_STAGE_ACCESS_SUCCESS";
export const GET_SUB_CONTRACTOR_STAGE_ACCESS_FAILURE =
  "GET_SUB_CONTRACTOR_STAGE_ACCESS_FAILURE";

// SAMPLE EVENTS
export const GET_SUB_CONTRACTOR_PROJECT_LOADING =
  "GET_SUB_CONTRACTOR_PROJECT_LOADING";
export const GET_SUB_CONTRACTOR_PROJECT_SUCCESS =
  "GET_SUB_CONTRACTOR_PROJECT_SUCCESS";
export const GET_SUB_CONTRACTOR_PROJECT_FAILURE =
  "GET_SUB_CONTRACTOR_PROJECT_FAILURE";
export const GET_SUB_CONTRACTOR_PROJECT_RESET =
  "GET_SUB_CONTRACTOR_PROJECT_RESET";

//ADD SUB CONTRACTOR
export const ADD_SUB_CONTRACTOR_LOADING = "ADD_SUB_CONTRACTOR_LOADING";
export const ADD_SUB_CONTRACTOR_SUCCESS = "ADD_SUB_CONTRACTOR_SUCCESS";
export const ADD_SUB_CONTRACTOR_FAILURE = "ADD_SUB_CONTRACTOR_FAILURE";
export const ADD_SUB_CONTRACTOR_RESET = "ADD_SUB_CONTRACTOR_RESET";

// REQUEST A DEMO
export const REQUEST_DEMO_LOADING = "REQUEST_DEMO_LOADING";
export const REQUEST_DEMO_SUCCESS = "REQUEST_DEMO_SUCCESS";
export const REQUEST_DEMO_FAILURE = "REQUEST_DEMO_FAILURE";

// DAILY TASK LIST
export const GET_TASK_LIST_LOADING = "GET_TASK_LIST_LOADING";
export const GET_TASK_LIST_SUCCESS = "GET_TASK_LIST_SUCCESS";
export const GET_TASK_LIST_FAILURE = "GET_TASK_LIST_FAILURE";
export const GET_TASK_LIST_RESET = "GET_TASK_LIST_FAILURE";

// DAILY TASK LIST
export const GET_BILL_LOADING = "GET_BILL_LOADING";
export const GET_BILL_SUCCESS = "GET_BILL_SUCCESS";
export const GET_BILL_FAILURE = "GET_BILL_FAILURE";
export const GET_BILL_RESET = "GET_BILL_RESET";

// GET MANAGE SUBSCRIPTION
export const GET_MANAGE_SUBSCRIPTION_LOADING =
  "GET_MANAGE_SUBSCRIPTION_LOADING";
export const GET_MANAGE_SUBSCRIPTION_SUCCESS =
  "GET_MANAGE_SUBSCRIPTION_SUCCESS";
export const GET_MANAGE_SUBSCRIPTION_FAILURE =
  "GET_MANAGE_SUBSCRIPTION_FAILURE";
export const GET_MANAGE_SUBSCRIPTION_RESET = "GET_MANAGE_SUBSCRIPTION_RESET";

// GET PROJECT REPORT
export const GET_PROJECT_REPORT_LOADING = "GET_PROJECT_REPORT_LOADING";
export const GET_PROJECT_REPORT_SUCCESS = "GET_PROJECT_REPORT_SUCCESS";
export const GET_PROJECT_REPORT_FAILURE = "GET_PROJECT_REPORT_FAILURE";

// CREATE NEW CHECKLIST ITEM
export const CREATE_NEW_CHECKLIST_ITEM_LOADING =
  "CREATE_NEW_CHECKLIST_ITEM_LOADING";
export const CREATE_NEW_CHECKLIST_ITEM_SUCCESS =
  "CREATE_NEW_CHECKLIST_ITEM_SUCCESS";
export const CREATE_NEW_CHECKLIST_ITEM_FAILURE =
  "CREATE_NEW_CHECKLIST_ITEM_FAILURE";
export const CREATE_NEW_CHECKLIST_ITEM_RESET =
  "CREATE_NEW_CHECKLIST_ITEM_RESET";

//CRM
//GET CRM BOARD
export const GET_CRM_BOARD_LOADING = "GET_CRM_BOARD_LOADING";
export const GET_CRM_BOARD_SUCCESS = "GET_CRM_BOARD_SUCCESS";
export const GET_CRM_BOARD_FAILURE = "GET_CRM_BOARD_FAILURE";
export const GET_CRM_BOARD_RESET = "GET_CRM_BOARD_RESET";

//GET CRM TEAM MEMBERS
export const GET_CRM_TEAM_MEMBERS_LOADING = "GET_CRM_TEAM_MEMBERS_LOADING";
export const GET_CRM_TEAM_MEMBERS_SUCCESS = "GET_CRM_TEAM_MEMBERS_SUCCESS";
export const GET_CRM_TEAM_MEMBERS_FAILURE = "GET_CRM_TEAM_MEMBERS_FAILURE";
export const GET_CRM_TEAM_MEMBERS_RESET = "GET_CRM_TEAM_MEMBERS_RESET";

//ADD CRM TEAM MEMBER
export const ADD_CRM_TEAM_MEMBER_LOADING = "ADD_CRM_TEAM_MEMBER_LOADING";
export const ADD_CRM_TEAM_MEMBER_SUCCESS = "ADD_CRM_TEAM_MEMBER_SUCCESS";
export const ADD_CRM_TEAM_MEMBER_FAILURE = "ADD_CRM_TEAM_MEMBER_FAILURE";
export const ADD_CRM_TEAM_MEMBER_RESET = "ADD_CRM_TEAM_MEMBER_RESET";

//DELETE CRM TEAM MEMBER
export const DELETE_CRM_TEAM_MEMBER_LOADING = "DELETE_CRM_TEAM_MEMBER_LOADING";
export const DELETE_CRM_TEAM_MEMBER_SUCCESS = "DELETE_CRM_TEAM_MEMBER_SUCCESS";
export const DELETE_CRM_TEAM_MEMBER_FAILURE = "DELETE_CRM_TEAM_MEMBER_FAILURE";
export const DELETE_CRM_TEAM_MEMBER_RESET = "DELETE_CRM_TEAM_MEMBER_RESET";

//DELETE CRM TEAM MEMBERS
export const DELETE_CRM_TEAM_MEMBERS_LOADING =
  "DELETE_CRM_TEAM_MEMBERS_LOADING";
export const DELETE_CRM_TEAM_MEMBERS_SUCCESS =
  "DELETE_CRM_TEAM_MEMBERS_SUCCESS";
export const DELETE_CRM_TEAM_MEMBERS_FAILURE =
  "DELETE_CRM_TEAM_MEMBERS_FAILURE";
export const DELETE_CRM_TEAM_MEMBERS_RESET = "DELETE_CRM_TEAM_MEMBERS_RESET";

//ADD NEW LEAD
export const ADD_NEW_LEAD_LOADING = "ADD_NEW_LEAD_LOADING";
export const ADD_NEW_LEAD_SUCCESS = "ADD_NEW_LEAD_SUCCESS";
export const ADD_NEW_LEAD_FAILURE = "ADD_NEW_LEAD_FAILURE";
export const ADD_NEW_LEAD_RESET = "ADD_NEW_LEAD_RESET";

//UPDATE LEAD
export const UPDATE_LEAD_LOADING = "UPDATE_LEAD_LOADING";
export const UPDATE_LEAD_SUCCESS = "UPDATE_LEAD_SUCCESS";
export const UPDATE_LEAD_FAILURE = "UPDATE_LEAD_FAILURE";
export const UPDATE_LEAD_RESET = "UPDATE_LEAD_RESET";

//DELETE LEAD
export const DELETE_LEAD_LOADING = "DELETE_LEAD_LOADING";
export const DELETE_LEAD_SUCCESS = "DELETE_LEAD_SUCCESS";
export const DELETE_LEAD_FAILURE = "DELETE_LEAD_FAILURE";
export const DELETE_LEAD_RESET = "DELETE_LEAD_RESET";

// GET PROPOSALS
export const GET_ALL_PROPOSALS_LOADING = "GET_ALL_PROPOSALS_LOADING";
export const GET_ALL_PROPOSALS_SUCCESS = "GET_ALL_PROPOSALS_SUCCESS";
export const GET_ALL_PROPOSALS_FAILURE = "GET_ALL_PROPOSALS_FAILURE";
export const GET_ALL_PROPOSALS_RESET = "GET_ALL_PROPOSALS_RESET";

// ADD PROPOSAL
export const ADD_PROPOSAL_LOADING = "ADD_PROPOSAL_LOADING";
export const ADD_PROPOSAL_SUCCESS = "ADD_PROPOSAL_SUCCESS";
export const ADD_PROPOSAL_FAILURE = "ADD_PROPOSAL_FAILURE";
export const ADD_PROPOSAL_RESET = "ADD_PROPOSAL_RESET";

// EDIT PROPOSAL
export const EDIT_PROPOSAL_LOADING = "EDIT_PROPOSAL_LOADING";
export const EDIT_PROPOSAL_SUCCESS = "EDIT_PROPOSAL_SUCCESS";
export const EDIT_PROPOSAL_FAILURE = "EDIT_PROPOSAL_FAILURE";
export const EDIT_PROPOSAL_RESET = "EDIT_PROPOSAL_RESET";

// RESHARE PROPOSAL
export const RESHARE_PROPOSAL_LOADING = "RESHARE_PROPOSAL_LOADING";
export const RESHARE_PROPOSAL_SUCCESS = "RESHARE_PROPOSAL_SUCCESS";
export const RESHARE_PROPOSAL_FAILURE = "RESHARE_PROPOSAL_FAILURE";
export const RESHARE_PROPOSAL_RESET = "RESHARE_PROPOSAL_RESET";

// GET ALL PROPOSAL TEMPLATES
export const GET_ALL_PROPOSAL_TEMPLATES_LOADING =
  "GET_ALL_PROPOSAL_TEMPLATES_LOADING";
export const GET_ALL_PROPOSAL_TEMPLATES_SUCCESS =
  "GET_ALL_PROPOSAL_TEMPLATES_SUCCESS";
export const GET_ALL_PROPOSAL_TEMPLATES_FAILURE =
  "GET_ALL_PROPOSAL_TEMPLATES_FAILURE";
export const GET_ALL_PROPOSAL_TEMPLATES_RESET =
  "GET_ALL_PROPOSAL_TEMPLATES_RESET";

// GET PROPOSAL TEMPLATE
export const GET_PROPOSAL_TEMPLATE_LOADING = "GET_PROPOSAL_TEMPLATE_LOADING";
export const GET_PROPOSAL_TEMPLATE_SUCCESS = "GET_PROPOSAL_TEMPLATE_SUCCESS";
export const GET_PROPOSAL_TEMPLATE_FAILURE = "GET_PROPOSAL_TEMPLATE_FAILURE";
export const GET_PROPOSAL_TEMPLATE_RESET = "GET_PROPOSAL_TEMPLATE_RESET";

// ADD PROPOSAL TEMPLATE
export const ADD_PROPOSAL_TEMPLATE_LOADING = "ADD_PROPOSAL_TEMPLATE_LOADING";
export const ADD_PROPOSAL_TEMPLATE_SUCCESS = "ADD_PROPOSAL_TEMPLATE_SUCCESS";
export const ADD_PROPOSAL_TEMPLATE_FAILURE = "ADD_PROPOSAL_TEMPLATE_FAILURE";
export const ADD_PROPOSAL_TEMPLATE_RESET = "ADD_PROPOSAL_TEMPLATE_RESET";

// UPDATE PROPOSAL TEMPLATE
export const UPDATE_PROPOSAL_TEMPLATE_LOADING =
  "UPDATE_PROPOSAL_TEMPLATE_LOADING";
export const UPDATE_PROPOSAL_TEMPLATE_SUCCESS =
  "UPDATE_PROPOSAL_TEMPLATE_SUCCESS";
export const UPDATE_PROPOSAL_TEMPLATE_FAILURE =
  "UPDATE_PROPOSAL_TEMPLATE_FAILURE";

// DELETE PROPOSAL TEMPLATE
export const DELETE_PROPOSAL_TEMPLATE_LOADING =
  "DELETE_PROPOSAL_TEMPLATE_LOADING";
export const DELETE_PROPOSAL_TEMPLATE_SUCCESS =
  "DELETE_PROPOSAL_TEMPLATE_SUCCESS";
export const DELETE_PROPOSAL_TEMPLATE_FAILURE =
  "DELETE_PROPOSAL_TEMPLATE_FAILURE";

// ADD BASIC PROPOSAL TEMPLATE
export const ADD_BASIC_PROPOSAL_TEMPLATE_LOADING =
  "ADD_BASIC_PROPOSAL_TEMPLATE_LOADING";
export const ADD_BASIC_PROPOSAL_TEMPLATE_SUCCESS =
  "ADD_BASIC_PROPOSAL_TEMPLATE_SUCCESS";
export const ADD_BASIC_PROPOSAL_TEMPLATE_FAILURE =
  "ADD_BASIC_PROPOSAL_TEMPLATE_FAILURE";
export const ADD_BASIC_PROPOSAL_TEMPLATE_RESET =
  "ADD_BASIC_PROPOSAL_TEMPLATE_RESET";

// GET PROPOSAL TEMPLATE PREVIEW
export const PREVIEW_PROPOSAL_TEMPLATE_LOADING =
  "PREVIEW_PROPOSAL_TEMPLATE_LOADING";
export const PREVIEW_PROPOSAL_TEMPLATE_SUCCESS =
  "PREVIEW_PROPOSAL_TEMPLATE_SUCCESS";
export const PREVIEW_PROPOSAL_TEMPLATE_FAILURE =
  "PREVIEW_PROPOSAL_TEMPLATE_FAILURE";
export const PREVIEW_PROPOSAL_TEMPLATE_RESET =
  "PREVIEW_PROPOSAL_TEMPLATE_RESET";

//EDIT PROJECT COMMERCIALS
export const EDIT_PROJECT_COMMERCIALS_LOADING =
  "EDIT_PROJECT_COMMERCIALS_LOADING";
export const EDIT_PROJECT_COMMERCIALS_SUCCESS =
  "EDIT_PROJECT_COMMERCIALS_SUCCESS";
export const EDIT_PROJECT_COMMERCIALS_FAILURE =
  "EDIT_PROJECT_COMMERCIALS_FAILURE";
export const EDIT_PROJECT_COMMERCIALS_RESET = "EDIT_PROJECT_COMMERCIALS_RESET";

//GET ALL COMPANIES
export const GET_ALL_COMPANIES_LOADING = "GET_ALL_COMPANIES_LOADING";
export const GET_ALL_COMPANIES_SUCCESS = "GET_ALL_COMPANIES_SUCCESS";
export const GET_ALL_COMPANIES_FAILURE = "GET_ALL_COMPANIES_FAILURE";
export const GET_ALL_COMPANIES_RESET = "GET_ALL_COMPANIES_RESET";

//ADD NEW COMPANY
export const ADD_NEW_COMPANY_LOADING = "ADD_NEW_COMPANY_LOADING";
export const ADD_NEW_COMPANY_SUCCESS = "ADD_NEW_COMPANY_SUCCESS";
export const ADD_NEW_COMPANY_FAILURE = "ADD_NEW_COMPANY_FAILURE";
export const ADD_NEW_COMPANY_RESET = "ADD_NEW_COMPANY_RESET";

//UPDATE COMPANY
export const UPDATE_COMPANY_LOADING = "UPDATE_COMPANY_LOADING";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE";
export const UPDATE_COMPANY_RESET = "UPDATE_COMPANY_RESET";

//UPDATE COMPANY
export const DELETE_COMPANY_LOADING = "DELETE_COMPANY_LOADING";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAILURE = "DELETE_COMPANY_FAILURE";
export const DELETE_COMPANY_RESET = "DELETE_COMPANY_RESET";

//UPDATE COMPANY
export const DELETE_COMPANIES_LOADING = "DELETE_COMPANIES_LOADING";
export const DELETE_COMPANIES_SUCCESS = "DELETE_COMPANIES_SUCCESS";
export const DELETE_COMPANIES_FAILURE = "DELETE_COMPANIES_FAILURE";
export const DELETE_COMPANIES_RESET = "DELETE_COMPANIES_RESET";

//GET CRM COMPANY DETAILS
export const GET_CRM_COMPANY_DETAILS_LOADING =
  "GET_CRM_COMPANY_DETAILS_LOADING";
export const GET_CRM_COMPANY_DETAILS_SUCCESS =
  "GET_CRM_COMPANY_DETAILS_SUCCESS";
export const GET_CRM_COMPANY_DETAILS_FAILURE =
  "GET_CRM_COMPANY_DETAILS_FAILURE";
export const GET_CRM_COMPANY_DETAILS_RESET = "GET_CRM_COMPANY_DETAILS_RESET";

//RENAME STAGE
export const RENAME_STAGE_LOADING = "RENAME_STAGE_LOADING";
export const RENAME_STAGE_SUCCESS = "RENAME_STAGE_SUCCESS";
export const RENAME_STAGE_FAILURE = "RENAME_STAGE_FAILURE";
export const RENAME_STAGE_RESET = "RENAME_STAGE_RESET";

//ADD STAGE
export const ADD_STAGE_LOADING = "ADD_STAGE_LOADING";
export const ADD_STAGE_SUCCESS = "ADD_STAGE_SUCCESS";
export const ADD_STAGE_FAILURE = "ADD_STAGE_FAILURE";
export const ADD_STAGE_RESET = "ADD_STAGE_RESET";

//DELETE STAGE
export const DELETE_STAGE_LOADING = "DELETE_STAGE_LOADING";
export const DELETE_STAGE_SUCCESS = "DELETE_STAGE_SUCCESS";
export const DELETE_STAGE_FAILURE = "DELETE_STAGE_FAILURE";
export const DELETE_STAGE_RESET = "DELETE_STAGE_RESET";

//MOVE STAGE
export const MOVE_STAGE_LOADING = "MOVE_STAGE_LOADING";
export const MOVE_STAGE_SUCCESS = "MOVE_STAGE_SUCCESS";
export const MOVE_STAGE_FAILURE = "MOVE_STAGE_FAILURE";
export const MOVE_STAGE_RESET = "MOVE_STAGE_RESET";

//UPDATE STAGE
export const UPDATE_STAGE_LOADING = "UPDATE_STAGE_LOADING";
export const UPDATE_STAGE_SUCCESS = "UPDATE_STAGE_SUCCESS";
export const UPDATE_STAGE_FAILURE = "UPDATE_STAGE_FAILURE";
export const UPDATE_STAGE_RESET = "UPDATE_STAGE_RESET";

//UPDATE LEAD ORDER
export const UPDATE_LEAD_ORDER_LOADING = "UPDATE_LEAD_ORDER_LOADING";
export const UPDATE_LEAD_ORDER_SUCCESS = "UPDATE_LEAD_ORDER_SUCCESS";
export const UPDATE_LEAD_ORDER_FAILURE = "UPDATE_LEAD_ORDER_FAILURE";
export const UPDATE_LEAD_ORDER_RESET = "UPDATE_LEAD_ORDER_RESET";

//GET LEAD DETAILS
export const GET_LEAD_DETAILS_LOADING = "GET_LEAD_DETAILS_LOADING";
export const GET_LEAD_DETAILS_SUCCESS = "GET_LEAD_DETAILS_SUCCESS";
export const GET_LEAD_DETAILS_FAILURE = "GET_LEAD_DETAILS_FAILURE";
export const GET_LEAD_DETAILS_RESET = "GET_LEAD_DETAILS_RESET";

//GET CHANNEL PARTNERS
export const GET_CHANNEL_PARTNERS_LOADING = "GET_CHANNEL_PARTNERS_LOADING";
export const GET_CHANNEL_PARTNERS_SUCCESS = "GET_CHANNEL_PARTNERS_SUCCESS";
export const GET_CHANNEL_PARTNERS_FAILURE = "GET_CHANNEL_PARTNERS_FAILURE";
export const GET_CHANNEL_PARTNERS_RESET = "GET_CHANNEL_PARTNERS_RESET";

//GET SPECIFIC CHANNEL PARTNER DETAILS
export const GET_SPECIFIC_CHANNEL_PARTNER_LOADING =
  "GET_SPECIFIC_CHANNEL_PARTNER_LOADING";
export const GET_SPECIFIC_CHANNEL_PARTNER_SUCCESS =
  "GET_SPECIFIC_CHANNEL_PARTNER_SUCCESS";
export const GET_SPECIFIC_CHANNEL_PARTNER_FAILURE =
  "GET_SPECIFIC_CHANNEL_PARTNER_FAILURE";
export const GET_SPECIFIC_CHANNEL_PARTNER_RESET =
  "GET_SPECIFIC_CHANNEL_PARTNER_RESET";

//ADD NEW CHANNEL PARTNER
export const ADD_NEW_CHANNEL_PARTNER_LOADING =
  "ADD_NEW_CHANNEL_PARTNER_LOADING";
export const ADD_NEW_CHANNEL_PARTNER_SUCCESS =
  "ADD_NEW_CHANNEL_PARTNER_SUCCESS";
export const ADD_NEW_CHANNEL_PARTNER_FAILURE =
  "ADD_NEW_CHANNEL_PARTNER_FAILURE";
export const ADD_NEW_CHANNEL_PARTNER_RESET = "ADD_NEW_CHANNEL_PARTNER_RESET";

//UPDATE CHANNEL PARTNER
export const UPDATE_CHANNEL_PARTNER_LOADING = "UPDATE_CHANNEL_PARTNER_LOADING";
export const UPDATE_CHANNEL_PARTNER_SUCCESS = "UPDATE_CHANNEL_PARTNER_SUCCESS";
export const UPDATE_CHANNEL_PARTNER_FAILURE = "UPDATE_CHANNEL_PARTNER_FAILURE";
export const UPDATE_CHANNEL_PARTNER_RESET = "UPDATE_CHANNEL_PARTNER_RESET";

//DELETE CHANNEL PARTNER
export const DELETE_CHANNEL_PARTNER_LOADING = "DELETE_CHANNEL_PARTNER_LOADING";
export const DELETE_CHANNEL_PARTNER_SUCCESS = "DELETE_CHANNEL_PARTNER_SUCCESS";
export const DELETE_CHANNEL_PARTNER_FAILURE = "DELETE_CHANNEL_PARTNER_FAILURE";
export const DELETE_CHANNEL_PARTNER_RESET = "DELETE_CHANNEL_PARTNER_RESET";

//GET CRM LEAD COMMENTS
export const GET_CRM_LEAD_COMMENTS_LOADING = "DELETE_CHANNEL_PARTNER_LOADING";
export const GET_CRM_LEAD_COMMENTS_SUCCESS = "GET_CRM_LEAD_COMMENTS_SUCCESS";
export const GET_CRM_LEAD_COMMENTS_FAILURE = "GET_CRM_LEAD_COMMENTS_FAILURE";
export const GET_CRM_LEAD_COMMENTS_RESET = "GET_CRM_LEAD_COMMENTS_RESET";

//ADD NEW LEAD COMMENT
export const ADD_NEW_LEAD_COMMENT_LOADING = "ADD_NEW_LEAD_COMMENT_LOADING";
export const ADD_NEW_LEAD_COMMENT_SUCCESS = "ADD_NEW_LEAD_COMMENT_SUCCESS";
export const ADD_NEW_LEAD_COMMENT_FAILURE = "ADD_NEW_LEAD_COMMENT_FAILURE";
export const ADD_NEW_LEAD_COMMENT_RESET = "ADD_NEW_LEAD_COMMENT_RESET";

//ADD NEW LEAD ATTACHMENT
export const ADD_NEW_LEAD_ATTACHMENT_LOADING =
  "ADD_NEW_LEAD_ATTACHMENT_LOADING";
export const ADD_NEW_LEAD_ATTACHMENT_SUCCESS =
  "ADD_NEW_LEAD_ATTACHMENT_SUCCESS";
export const ADD_NEW_LEAD_ATTACHMENT_FAILURE =
  "ADD_NEW_LEAD_ATTACHMENT_FAILURE";
export const ADD_NEW_LEAD_ATTACHMENT_RESET = "ADD_NEW_LEAD_ATTACHMENT_RESET";

//ADD LEAD TASK
export const ADD_LEAD_TASK_LOADING = "ADD_LEAD_TASK_LOADING";
export const ADD_LEAD_TASK_SUCCESS = "ADD_LEAD_TASK_SUCCESS";
export const ADD_LEAD_TASK_FAILURE = "ADD_LEAD_TASK_FAILURE";
export const ADD_LEAD_TASK_RESET = "ADD_LEAD_TASK_RESET";

//IMPORT LEADS
export const IMPORT_LEADS_LOADING = "IMPORT_LEADS_LOADING";
export const IMPORT_LEADS_SUCCESS = "IMPORT_LEADS_SUCCESS";
export const IMPORT_LEADS_FAILURE = "IMPORT_LEADS_FAILURE";
export const IMPORT_LEADS_RESET = "IMPORT_LEADS_RESET";

//ADD LEAD TASK
export const GET_ALL_ACTIVITIES_LOADING = "GET_ALL_ACTIVITIES_LOADING";
export const GET_ALL_ACTIVITIES_SUCCESS = "GET_ALL_ACTIVITIES_SUCCESS";
export const GET_ALL_ACTIVITIES_FAILURE = "GET_ALL_ACTIVITIES_FAILURE";
export const GET_ALL_ACTIVITIES_RESET = "GET_ALL_ACTIVITIES_RESET";

// CUSTOMER DASHBOARD
export const GET_CUSTOMER_DASHBOARD_LOADING = "GET_CUSTOMER_DASHBOARD_LOADING";
export const GET_CUSTOMER_DASHBOARD_SUCCESS = "GET_CUSTOMER_DASHBOARD_SUCCESS";
export const GET_CUSTOMER_DASHBOARD_FAILURE = "GET_CUSTOMER_DASHBOARD_FAILURE";
export const GET_CUSTOMER_DASHBOARD_RESET = "GET_CUSTOMER_DASHBOARD_RESET";

// TOGGLE TASK OR START TASK
export const TOGGLE_TASK_LOADING = "TOGGLE_TASK_LOADING";
export const TOGGLE_TASK_SUCCESS = "TOGGLE_TASK_SUCCESS";
export const TOGGLE_TASK_FAILURE = "TOGGLE_TASK_FAILURE";

// CUSTOMER EVENT APPROVAL
export const POST_CUSTOMER_EVENT_APPROVAL_LOADING =
  "POST_CUSTOMER_EVENT_APPROVAL_LOADING";
export const POST_CUSTOMER_EVENT_APPROVAL_SUCCESS =
  "POST_CUSTOMER_EVENT_APPROVAL_SUCCESS";
export const POST_CUSTOMER_EVENT_APPROVAL_FAILURE =
  "POST_CUSTOMER_EVENT_APPROVAL_FAILURE";

// UPDATE CUSTOMER APPROVAL REQUEST
export const POST_UPDATE_CUSTOMER_APPROVAL_REQUEST_LOADING =
  "POST_UPDATE_CUSTOMER_APPROVAL_REQUEST_LOADING";
export const POST_UPDATE_CUSTOMER_APPROVAL_REQUEST_SUCCESS =
  "POST_UPDATE_CUSTOMER_APPROVAL_REQUEST_SUCCESS";
export const POST_UPDATE_CUSTOMER_APPROVAL_REQUEST_FAILURE =
  "POST_UPDATE_CUSTOMER_APPROVAL_REQUEST_FAILURE";

// FILTER ON GOING TASKS
export const FILTER_ON_GOING_TASKS_LOADING = "FILTER_ON_GOING_TASKS_LOADING";
export const FILTER_ON_GOING_TASKS_SUCCESS = "FILTER_ON_GOING_TASKS_SUCCESS";
export const FILTER_ON_GOING_TASKS_FAILURE = "FILTER_ON_GOING_TASKS_FAILURE";

//VENDOR DASHBOARD
export const GET_VENDOR_DASHBOARD_LOADING = "GET_VENDOR_DASHBOARD_LOADING";
export const GET_VENDOR_DASHBOARD_SUCCESS = "GET_VENDOR_DASHBOARD_SUCCESS";
export const GET_VENDOR_DASHBOARD_FAILURE = "GET_VENDOR_DASHBOARD_FAILURE";
export const GET_VENDOR_DASHBOARD_RESET = "GET_VENDOR_DASHBOARD_RESET";

//START TASK
export const START_TASK_LOADING = "START_TASK_LOADING";
export const START_TASK_SUCCESS = "START_TASK_SUCCESS";
export const START_TASK_FAILURE = "START_TASK_FAILURE";
export const START_TASK_RESET = "START_TASK_RESET";

//DISAPPROVE EVENT
export const DISAPPROVE_EVENT_LOADING = "DISAPPROVE_EVENT_LOADING";
export const DISAPPROVE_EVENT_SUCCESS = "DISAPPROVE_EVENT_SUCCESS";
export const DISAPPROVE_EVENT_FAILURE = "DISAPPROVE_EVENT_FAILURE";
export const DISAPPROVE_EVENT_RESET = "DISAPPROVE_EVENT_RESET";

//UPDATE MATERIAL HANDLING
export const UPDATE_MATERIAL_HANDLING_LOADING =
  "UPDATE_MATERIAL_HANDLING_LOADING";
export const UPDATE_MATERIAL_HANDLING_SUCCESS =
  "UPDATE_MATERIAL_HANDLING_SUCCESS";
export const UPDATE_MATERIAL_HANDLING_FAILURE =
  "UPDATE_MATERIAL_HANDLING_FAILURE";
export const UPDATE_MATERIAL_HANDLING_RESET = "UPDATE_MATERIAL_HANDLING_RESET";

// GET PROJECT DRAFT
export const GET_PLANNING_EVENTS_LOADING = "GET_PLANNING_EVENTS_LOADING";
export const GET_PLANNING_EVENTS_SUCCESS = "GET_PLANNING_EVENTS_SUCCESS";
export const GET_PLANNING_EVENTS_FAILURE = "GET_PLANNING_EVENTS_FAILURE";
export const GET_PLANNING_EVENTS_RESET = "GET_PLANNING_EVENTS_RESET";

// GET PLANNING STAGES
export const GET_PLANNING_STAGES_LOADING = "GET_PLANNING_STAGES_LOADING";
export const GET_PLANNING_STAGES_SUCCESS = "GET_PLANNING_STAGES_SUCCESS";
export const GET_PLANNING_STAGES_FAILURE = "GET_PLANNING_STAGES_FAILURE";
export const GET_PLANNING_STAGES_RESET = "GET_PLANNING_STAGES_RESET";

// UPDATE PLANNING STAGE DRAFT
export const UPDATE_PLANNING_STAGE_DRAFT_LOADING =
  "UPDATE_PLANNING_STAGE_DRAFT_LOADING";
export const UPDATE_PLANNING_STAGE_DRAFT_SUCCESS =
  "UPDATE_PLANNING_STAGE_DRAFT_SUCCESS";
export const UPDATE_PLANNING_STAGE_DRAFT_FAILURE =
  "UPDATE_PLANNING_STAGE_DRAFT_FAILURE";
export const UPDATE_PLANNING_STAGE_DRAFT_RESET =
  "UPDATE_PLANNING_STAGE_DRAFT_RESET";

// UPDATE PLANNING EVENT DRAFT
export const UPDATE_PLANNING_EVENT_DRAFT_LOADING =
  "UPDATE_PLANNING_EVENT_DRAFT_LOADING";
export const UPDATE_PLANNING_EVENT_DRAFT_SUCCESS =
  "UPDATE_PLANNING_EVENT_DRAFT_SUCCESS";
export const UPDATE_PLANNING_EVENT_DRAFT_FAILURE =
  "UPDATE_PLANNING_EVENT_DRAFT_FAILURE";
export const UPDATE_PLANNING_EVENT_DRAFT_RESET =
  "UPDATE_PLANNING_EVENT_DRAFT_RESET";

// UPDATE PROJECT  DATE
export const UPDATE_PROJECT_DATE_LOADING = "UPDATE_PROJECT_DATE_LOADING";
export const UPDATE_PROJECT_DATE_SUCCESS = "UPDATE_PROJECT_DATE_SUCCESS";
export const UPDATE_PROJECT_DATE_FAILURE = "UPDATE_PROJECT_DATE_FAILURE";

// CHANGE BULK EVENT STATUS
export const CHANGE_BULK_EVENT_STATUS_LOADING =
  "CHANGE_BULK_EVENT_STATUS_LOADING";
export const CHANGE_BULK_EVENT_STATUS_SUCCESS =
  "CHANGE_BULK_EVENT_STATUS_SUCCESS";
export const CHANGE_BULK_EVENT_STATUS_FAILURE =
  "CHANGE_BULK_EVENT_STATUS_FAILURE";
export const CHANGE_BULK_EVENT_STATUS_RESET = "CHANGE_BULK_EVENT_STATUS_RESET";

// RESET TO INITIAL STATE
export const RESET_INITIAL_STATE = "RESET INITIAL STATE";

// GET USER TICKETS
export const GET_USER_TICKETS_LOADING = "GET_USER_TICKETS_LOADING";
export const GET_USER_TICKETS_SUCCESS = "GET_USER_TICKETS_SUCCESS";
export const GET_USER_TICKETS_FAILURE = "GET_USER_TICKETS_FAILURE";
export const GET_USER_TICKETS_RESET = "GET_USER_TICKETS_RESET";

// GET COMPANY TICKETS
export const GET_COMPANY_TICKETS_LOADING = "GET_COMPANY_TICKETS_LOADING";
export const GET_COMPANY_TICKETS_SUCCESS = "GET_COMPANY_TICKETS_SUCCESS";
export const GET_COMPANY_TICKETS_FAILURE = "GET_COMPANY_TICKETS_FAILURE";
export const GET_COMPANY_TICKETS_RESET = "GET_COMPANY_TICKETS_RESET";

// REASSIGN TICKET
export const REASSIGN_TICKET_LOADING = "REASSIGN_TICKET_LOADING";
export const REASSIGN_TICKET_SUCCESS = "REASSIGN_TICKET_SUCCESS";
export const REASSIGN_TICKET_FAILURE = "REASSIGN_TICKET_FAILURE";
export const REASSIGN_TICKET_RESET = "REASSIGN_TICKET_RESET";

// UPDATE TICKET
export const UPDATE_TICKET_LOADING = "UPDATE_TICKET_LOADING";
export const UPDATE_TICKET_SUCCESS = "UPDATE_TICKET_SUCCESS";
export const UPDATE_TICKET_FAILURE = "UPDATE_TICKET_FAILURE";
export const UPDATE_TICKET_RESET = "UPDATE_TICKET_RESET";

// GET EPCS
export const GET_EPCS_LOADING = "GET_EPCS_LOADING";
export const GET_EPCS_SUCCESS = "GET_EPCS_SUCCESS";
export const GET_EPCS_FAILURE = "GET_EPCS_FAILURE";
export const GET_EPCS_RESET = "GET_EPCS_RESET";

// ASSIGN EPCS
export const ASSIGN_EPCS_LOADING = "ASSIGN_EPCS_LOADING";
export const ASSIGN_EPCS_SUCCESS = "ASSIGN_EPCS_SUCCESS";
export const ASSIGN_EPCS_FAILURE = "ASSIGN_EPCS_FAILURE";
export const ASSIGN_EPCS_RESET = "ASSIGN_EPCS_RESET";

// UPDATE EPCS
export const UPDATE_EPC_LOADING = "UPDATE_EPC_LOADING";
export const UPDATE_EPC_SUCCESS = "UPDATE_EPC_SUCCESS";
export const UPDATE_EPC_FAILURE = "UPDATE_EPC_FAILURE";
export const UPDATE_EPC_RESET = "UPDATE_EPC_RESET";

// GET BASIC EPCS
export const GET_BASIC_EPCS_LOADING = "GET_BASIC_EPCS_LOADING";
export const GET_BASIC_EPCS_SUCCESS = "GET_BASIC_EPCS_SUCCESS";
export const GET_BASIC_EPCS_FAILURE = "GET_BASIC_EPCS_FAILURE";
export const GET_BASIC_EPCS_RESET = "GET_BASIC_EPCS_RESET";

// ADD EPC TEAM MEMBER
export const ADD_EPC_TEAM_MEMBER_LOADING = "ADD_EPC_TEAM_MEMBER_LOADING";
export const ADD_EPC_TEAM_MEMBER_SUCCESS = "ADD_EPC_TEAM_MEMBER_SUCCESS";
export const ADD_EPC_TEAM_MEMBER_FAILURE = "ADD_EPC_TEAM_MEMBER_FAILURE";
export const ADD_EPC_TEAM_MEMBER_RESET = "ADD_EPC_TEAM_MEMBER_RESET";

// ASSIGN EPC TEAM
export const ASSIGN_EPC_TEAM_LOADING = "ASSIGN_EPC_TEAM_LOADING";
export const ASSIGN_EPC_TEAM_SUCCESS = "ASSIGN_EPC_TEAM_SUCCESS";
export const ASSIGN_EPC_TEAM_FAILURE = "ASSIGN_EPC_TEAM_FAILURE";
export const ASSIGN_EPC_TEAM_RESET = "ASSIGN_EPC_TEAM_RESET";

// GET EPC TEAM MEMBERS
export const GET_EPC_TEAM_MEMBERS_LOADING = "GET_EPC_TEAM_MEMBERS_LOADING";
export const GET_EPC_TEAM_MEMBERS_SUCCESS = "GET_EPC_TEAM_MEMBERS_SUCCESS";
export const GET_EPC_TEAM_MEMBERS_FAILURE = "GET_EPC_TEAM_MEMBERS_FAILURE";
export const GET_EPC_TEAM_MEMBERS_RESET = "GET_EPC_TEAM_MEMBERS_RESET";

// TRANSFER TASKS
export const TRANSFER_TASKS_LOADING = "TRANSFER_TASKS_LOADING";
export const TRANSFER_TASKS_SUCCESS = "TRANSFER_TASKS_SUCCESS";
export const TRANSFER_TASKS_FAILURE = "TRANSFER_TASKS_FAILURE";
export const TRANSFER_TASKS_RESET = "TRANSFER_TASKS_RESET";

// FILTER ACTIVITY
export const FILTER_ACTIVITY_LIST_LOADING = "FILTER_ACTIVITY_LIST_LOADING";
export const FILTER_ACTIVITY_LIST_SUCCESS = "FILTER_ACTIVITY_LIST_SUCCESS";
export const FILTER_ACTIVITY_LIST_FAILURE = "FILTER_ACTIVITY_LIST_FAILURE";

// GET PROJECT MANAGERS
export const GET_PROJECT_MANAGERS_LOADING = "GET_PROJECT_MANAGERS_LOADING";
export const GET_PROJECT_MANAGERS_SUCCESS = "GET_PROJECT_MANAGERS_SUCCESS";
export const GET_PROJECT_MANAGERS_FAILURE = "GET_PROJECT_MANAGERS_FAILURE";
export const GET_PROJECT_MANAGERS_RESET = "GET_PROJECT_MANAGERS_RESET";

// ADD PROJECT MANAGER
export const ADD_PROJECT_MANAGER_LOADING = "ADD_PROJECT_MANAGER_LOADING";
export const ADD_PROJECT_MANAGER_SUCCESS = "ADD_PROJECT_MANAGER_SUCCESS";
export const ADD_PROJECT_MANAGER_FAILURE = "ADD_PROJECT_MANAGER_FAILURE";
export const ADD_PROJECT_MANAGER_RESET = "ADD_PROJECT_MANAGER_RESET";

// UPDATE PROJECT MANAGER
export const UPDATE_PROJECT_MANAGER_LOADING = "UPDATE_PROJECT_MANAGER_LOADING";
export const UPDATE_PROJECT_MANAGER_SUCCESS = "UPDATE_PROJECT_MANAGER_SUCCESS";
export const UPDATE_PROJECT_MANAGER_FAILURE = "UPDATE_PROJECT_MANAGER_FAILURE";
export const UPDATE_PROJECT_MANAGER_RESET = "UPDATE_PROJECT_MANAGER_RESET";

// ASSIGN PROJECTS TO PROJECT MANAGER
export const ASSIGN_PROJECTS_LOADING = "ASSIGN_PROJECTS_LOADING";
export const ASSIGN_PROJECTS_SUCCESS = "ASSIGN_PROJECTS_SUCCESS";
export const ASSIGN_PROJECTS_FAILURE = "ASSIGN_PROJECTS_FAILURE";
export const ASSIGN_PROJECTS_RESET = "ASSIGN_PROJECTS_RESET";

// GET CUSTOMER DOCS DETAILS
export const GET_CUSTOMER_DOCS_DETAILS_LOADING =
  "GET_CUSTOMER_DOCS_DETAILS_LOADING";
export const GET_CUSTOMER_DOCS_DETAILS_SUCCESS =
  "GET_CUSTOMER_DOCS_DETAILS_SUCCESS";
export const GET_CUSTOMER_DOCS_DETAILS_FAILURE =
  "GET_CUSTOMER_DOCS_DETAILS_FAILURE";

//POST BOQ
export const POST_BOQ_LOADING = "POST_BOQ_LOADING";
export const POST_BOQ_SUCCESS = "POST_BOQ_SUCCESS";
export const POST_BOQ_FAILURE = "POST_BOQ_FAILURE";
export const POST_BOQ_RESET = "POST_BOQ_RESET";

//DELETE BOQ
export const DELETE_BOQ_LOADING = "DELETE_BOQ_LOADING";
export const DELETE_BOQ_SUCCESS = "DELETE_BOQ_SUCCESS";
export const DELETE_BOQ_FAILURE = "DELETE_BOQ_FAILURE";
export const DELETE_BOQ_RESET = "DELETE_BOQ_RESET";

//ADD NEW DOCUMENT TYPE
export const ADD_NEW_DOCUMENT_TYPE_LOADING = "ADD_NEW_DOCUMENT_TYPE_LOADING";
export const ADD_NEW_DOCUMENT_TYPE_SUCCESS = "ADD_NEW_DOCUMENT_TYPE_SUCCESS";
export const ADD_NEW_DOCUMENT_TYPE_FAILURE = "ADD_NEW_DOCUMENT_TYPE_FAILURE";
export const ADD_NEW_DOCUMENT_TYPE_RESET = "ADD_NEW_DOCUMENT_TYPE_RESET";

//RAISE PO
export const RAISE_PO_LOADING = "RAISE_PO_LOADING";
export const RAISE_PO_SUCCESS = "RAISE_PO_SUCCESS";
export const RAISE_PO_FAILURE = "RAISE_PO_FAILURE";
export const RAISE_PO_RESET = "RAISE_PO_RESET";

// POST FLOAT RFQ
export const FLOAT_RFQ_LOADING = "FLOAT_RFQ_LOADING";
export const FLOAT_RFQ_SUCCESS = "FLOAT_RFQ_SUCCESS";
export const FLOAT_RFQ_FAILURE = "FLOAT_RFQ_FAILURE";

// GET SITE VISIT

export const GET_SITE_VISIT_LOADING = "GET_SITE_VISIT_LOADING";
export const GET_SITE_VISIT_SUCCESS = "GET_SITE_VISIT_SUCCESS";
export const GET_SITE_VISIT_FAILURE = "GET_SITE_VISIT_FAILURE";

// GET SITE VISIT DETAILS

export const GET_SITE_VISIT_DETAILS_LOADING = "GET_SITE_VISIT_DETAILS_LOADING";
export const GET_SITE_VISIT_DETAILS_SUCCESS = "GET_SITE_VISIT_DETAILS_SUCCESS";
export const GET_SITE_VISIT_DETAILS_FAILURE = "GET_SITE_VISIT_DETAILS_FAILURE";

// GET SITE VISIT FORM DETAILS

export const GET_SITE_VISIT_FORM_DETAILS_LOADING =
  "GET_SITE_VISIT_FORM_DETAILS_LOADING";
export const GET_SITE_VISIT_FORM_DETAILS_SUCCESS =
  "GET_SITE_VISIT_FORM_DETAILS_SUCCESS";
export const GET_SITE_VISIT_FORM_DETAILS_FAILURE =
  "GET_SITE_VISIT_FORM_DETAILS_FAILURE";

// GET COMPANY SPOC

export const GET_COMPANY_SPOC_LOADING = "GET_COMPANY_SPOC_LOADING";
export const GET_COMPANY_SPOC_SUCCESS = "GET_COMPANY_SPOC_SUCCESS";
export const GET_COMPANY_SPOC_FAILURE = "GET_COMPANY_SPOC_FAILURE";
//CREATE PUNCH POINT
export const CREATE_PUNCH_POINT_LOADING = "CREATE_PUNCH_POINT_LOADING";
export const CREATE_PUNCH_POINT_SUCCESS = "CREATE_PUNCH_POINT_SUCCESS";
export const CREATE_PUNCH_POINT_FAILURE = "CREATE_PUNCH_POINT_FAILURE";
export const CREATE_PUNCH_POINT_RESET = "CREATE_PUNCH_POINT_RESET";

//APPROVE DISAPPROVE PUNCH POINT
export const APPROVE_DISAPPROVE_PUNCH_POINT_LOADING =
  "APPROVE_DISAPPROVE_PUNCH_POINT_LOADING";
export const APPROVE_DISAPPROVE_PUNCH_POINT_SUCCESS =
  "APPROVE_DISAPPROVE_PUNCH_POINT_SUCCESS";
export const APPROVE_DISAPPROVE_PUNCH_POINT_FAILURE =
  "APPROVE_DISAPPROVE_PUNCH_POINT_FAILURE";
export const APPROVE_DISAPPROVE_PUNCH_POINT_RESET =
  "APPROVE_DISAPPROVE_PUNCH_POINT_RESET";

//UPDATE PUNCH POINT
export const UPDATE_PUNCH_POINT_LOADING = "UPDATE_PUNCH_POINT_LOADING";
export const UPDATE_PUNCH_POINT_SUCCESS = "UPDATE_PUNCH_POINT_SUCCESS";
export const UPDATE_PUNCH_POINT_FAILURE = "UPDATE_PUNCH_POINT_FAILURE";
export const UPDATE_PUNCH_POINT_RESET = "UPDATE_PUNCH_POINT_RESET";

//ADD TERMINATION AND STRING DETAILS
export const ADD_TERMINATION_AND_STRING_DETAILS_LOADING =
  "ADD_TERMINATION_AND_STRING_DETAILS_LOADING";
export const ADD_TERMINATION_AND_STRING_DETAILS_SUCCESS =
  "ADD_TERMINATION_AND_STRING_DETAILS_SUCCESS";
export const ADD_TERMINATION_AND_STRING_DETAILS_FAILURE =
  "ADD_TERMINATION_AND_STRING_DETAILS_FAILURE";
export const ADD_TERMINATION_AND_STRING_DETAILS_RESET =
  "ADD_TERMINATION_AND_STRING_DETAILS_RESET";

//UPDATE TERMINATION AND STRING DETAILS
export const UPDATE_TERMINATION_AND_STRING_DETAILS_LOADING =
  "UPDATE_TERMINATION_AND_STRING_DETAILS_LOADING";
export const UPDATE_TERMINATION_AND_STRING_DETAILS_SUCCESS =
  "UPDATE_TERMINATION_AND_STRING_DETAILS_SUCCESS";
export const UPDATE_TERMINATION_AND_STRING_DETAILS_FAILURE =
  "UPDATE_TERMINATION_AND_STRING_DETAILS_FAILURE";
export const UPDATE_TERMINATION_AND_STRING_DETAILS_RESET =
  "UPDATE_TERMINATION_AND_STRING_DETAILS_RESET";

//UPDATE MATERIAL STATUS
export const UPDATE_MATERIAL_STATUS_LOADING = "UPDATE_MATERIAL_STATUS_LOADING";
export const UPDATE_MATERIAL_STATUS_SUCCESS = "UPDATE_MATERIAL_STATUS_SUCCESS";
export const UPDATE_MATERIAL_STATUS_FAILURE = "UPDATE_MATERIAL_STATUS_FAILURE";
export const UPDATE_MATERIAL_STATUS_RESET = "UPDATE_MATERIAL_STATUS_RESET";

//UPDATE COMPONENT STATUS
export const UPDATE_COMPONENT_STATUS_LOADING =
  "UPDATE_COMPONENT_STATUS_LOADING";
export const UPDATE_COMPONENT_STATUS_SUCCESS =
  "UPDATE_COMPONENT_STATUS_SUCCESS";
export const UPDATE_COMPONENT_STATUS_FAILURE =
  "UPDATE_COMPONENT_STATUS_FAILURE";
export const UPDATE_COMPONENT_STATUS_RESET = "UPDATE_COMPONENT_STATUS_RESET";

//UPDATE COMPONENT PROGRESS
export const UPDATE_COMPONENT_PROGRESS_LOADING =
  "UPDATE_COMPONENT_PROGRESS_LOADING";
export const UPDATE_COMPONENT_PROGRESS_SUCCESS =
  "UPDATE_COMPONENT_PROGRESS_SUCCESS";
export const UPDATE_COMPONENT_PROGRESS_FAILURE =
  "UPDATE_COMPONENT_PROGRESS_FAILURE";
export const UPDATE_COMPONENT_PROGRESS_RESET =
  "UPDATE_COMPONENT_PROGRESS_RESET";

//EDIT TERMINATION OF STRINGS SPEC
export const EDIT_TERMINATION_OF_STRINGS_SPEC_LOADING =
  "EDIT_TERMINATION_OF_STRINGS_SPEC_LOADING";
export const EDIT_TERMINATION_OF_STRINGS_SPEC_SUCCESS =
  "EDIT_TERMINATION_OF_STRINGS_SPEC_SUCCESS";
export const EDIT_TERMINATION_OF_STRINGS_SPEC_FAILURE =
  "EDIT_TERMINATION_OF_STRINGS_SPEC_FAILURE";
export const EDIT_TERMINATION_OF_STRINGS_SPEC_RESET =
  "EDIT_TERMINATION_OF_STRINGS_SPEC_RESET";

// UPLOAD PROJECT INVENTORY
export const UPLOAD_PROJECT_INVENTORY_LOADING =
  "UPLOAD_PROJECT_INVENTORY_LOADING";
export const UPLOAD_PROJECT_INVENTORY_SUCCESS =
  "UPLOAD_PROJECT_INVENTORY_SUCCESS";
export const UPLOAD_PROJECT_INVENTORY_FAILURE =
  "UPLOAD_PROJECT_INVENTORY_FAILURE";
export const UPLOAD_PROJECT_INVENTORY_RESET = "UPLOAD_PROJECT_INVENTORY_RESET";

// DELETE PROJECT INVENTORY
export const DELETE_PROJECT_INVENTORY_LOADING =
  "DELETE_PROJECT_INVENTORY_LOADING";
export const DELETE_PROJECT_INVENTORY_SUCCESS =
  "DELETE_PROJECT_INVENTORY_SUCCESS";
export const DELETE_PROJECT_INVENTORY_FAILURE =
  "DELETE_PROJECT_INVENTORY_FAILURE";
export const DELETE_PROJECT_INVENTORY_RESET = "DELETE_PROJECT_INVENTORY_RESET";

// GET VENDOR TEAM DASHBOARD
export const GET_VENDOR_TEAM_DASHBOARD_LOADING =
  "GET_VENDOR_TEAM_DASHBOARD_LOADING";
export const GET_VENDOR_TEAM_DASHBOARD_SUCCESS =
  "GET_VENDOR_TEAM_DASHBOARD_SUCCESS";
export const GET_VENDOR_TEAM_DASHBOARD_FAILURE =
  "GET_VENDOR_TEAM_DASHBOARD_FAILURE";
export const GET_VENDOR_TEAM_DASHBOARD_RESET =
  "GET_VENDOR_TEAM_DASHBOARD_RESET";

// DATA DASHBOARD LOGIN
export const DATA_DASHBOARD_LOGIN_LOADING = "DATA_DASHBOARD_LOGIN_LOADING";
export const DATA_DASHBOARD_LOGIN_SUCCESS = "DATA_DASHBOARD_LOGIN_SUCCESS";
export const DATA_DASHBOARD_LOGIN_FAILURE = "DATA_DASHBOARD_LOGIN_FAILURE";
export const DATA_DASHBOARD_LOGIN_RESET = "DATA_DASHBOARD_LOGIN_RESET";

// GET FINANCE LOAN DETAILS(FINVERV)

export const GET_FINANCE_LOAN_DETAILS_LOADING =
  "GET_FINANCE_LOAN_DETAILS_LOADING";
export const GET_FINANCE_LOAN_DETAILS_SUCCESS =
  "GET_FINANCE_LOAN_DETAILS_SUCCESS";
export const GET_FINANCE_LOAN_DETAILS_FAILURE =
  "GET_FINANCE_LOAN_DETAILS_FAILURE";

// GET LOAN LIST(FINVERV)
export const GET_LOAN_LIST_LOADING = "GET_LOAN_LIST_LOADING";
export const GET_LOAN_LIST_SUCCESS = "GET_LOAN_LIST_SUCCESS";
export const GET_LOAN_LIST_FAILURE = "GET_LOAN_LIST_FAILURE";

//GET LINE DETAILS(LENDER FINVERV)
export const GET_LINE_DETAILS_LOADING = "GET_LINE_DETAILS_LOADING";
export const GET_LINE_DETAILS_SUCCESS = "GET_LINE_DETAILS_SUCCESS";
export const GET_LINE_DETAILS_FAILURE = "GET_LINE_DETAILS_FAILURE";

// GET COMPANY LEVEL LOAN DETAILS
export const GET_COMPANY_LEVEL_LOAN_DETAILS_LOADING =
  "GET_COMPANY_LEVEL_LOAN_DETAILS_LOADING";
export const GET_COMPANY_LEVEL_LOAN_DETAILS_SUCCESS =
  "GET_COMPANY_LEVEL_LOAN_DETAILS_SUCCESS";
export const GET_COMPANY_LEVEL_LOAN_DETAILS_FAILURE =
  "GET_COMPANY_LEVEL_LOAN_DETAILS_FAILURE";

// GET ALL LOAN LEADS
export const GET_ALL_LOAN_LEADS_LOADING = "GET_ALL_LOAN_LEADS_LOADING";
export const GET_ALL_LOAN_LEADS_SUCCESS = "GET_ALL_LOAN_LEADS_SUCCESS";
export const GET_ALL_LOAN_LEADS_FAILURE = "GET_ALL_LOAN_LEADS_FAILURE";

// GET DATA DASHBOARD COMPANY DETAILS
export const GET_DATA_DASHBOARD_COMPANY_DETAILS_LOADING =
  "GET_DATA_DASHBOARD_COMPANY_DETAILS_LOADING";
export const GET_DATA_DASHBOARD_COMPANY_DETAILS_SUCCESS =
  "GET_DATA_DASHBOARD_COMPANY_DETAILS_SUCCESS";
export const GET_DATA_DASHBOARD_COMPANY_DETAILS_FAILURE =
  "GET_DATA_DASHBOARD_COMPANY_DETAILS_FAILURE";
export const GET_DATA_DASHBOARD_COMPANY_DETAILS_RESET =
  "GET_DATA_DASHBOARD_COMPANY_DETAILS_RESET";

// GET OTP
export const GET_OTP_LOADING = "GET_OTP_LOADING";
export const GET_OTP_SUCCESS = "GET_OTP_SUCCESS";
export const GET_OTP_FAILURE = "GET_OTP_FAILURE";
export const GET_OTP_RESET = "GET_OTP_RESET";

// LANDING PAGE FINANCE SIGNUP
export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

// LANDING PAGE FINANCE LOGIN
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// VENDOR REQUEST FINANCE
export const VENDOR_REQUEST_FINANCE_LOADING = "VENDOR_REQUEST_FINANCE_LOADING";
export const VENDOR_REQUEST_FINANCE_SUCCESS = "VENDOR_REQUEST_FINANCE_SUCCESS";
export const VENDOR_REQUEST_FINANCE_FAILURE = "VENDOR_REQUEST_FINANCE_FAILURE";
export const VENDOR_REQUEST_FINANCE_RESET = "VENDOR_REQUEST_FINANCE_RESET";

// CUSTOMER REQUEST FINANCE
export const CUSTOMER_REQUEST_FINANCE_LOADING =
  "CUSTOMER_REQUEST_FINANCE_LOADING";
export const CUSTOMER_REQUEST_FINANCE_SUCCESS =
  "CUSTOMER_REQUEST_FINANCE_SUCCESS";
export const CUSTOMER_REQUEST_FINANCE_FAILURE =
  "CUSTOMER_REQUEST_FINANCE_FAILURE";
export const CUSTOMER_REQUEST_FINANCE_RESET = "CUSTOMER_REQUEST_FINANCE_RESET";

// GET PAST PROJECTS FOR CRM TEAM MEMBER
export const GET_PAST_PROJECTS_CRM_LOADING = "GET_PAST_PROJECTS_CRM_LOADING";
export const GET_PAST_PROJECTS_CRM_SUCCESS = "GET_PAST_PROJECTS_CRM_SUCCESS";
export const GET_PAST_PROJECTS_CRM_FAILURE = "GET_PAST_PROJECTS_CRM_FAILURE";

// ADD PAST PROJECTS FOR CRM TEAM MEMBER
export const ADD_PAST_PROJECTS_CRM_LOADING = "ADD_PAST_PROJECTS_CRM_LOADING";
export const ADD_PAST_PROJECTS_CRM_SUCCESS = "ADD_PAST_PROJECTS_CRM_SUCCESS";
export const ADD_PAST_PROJECTS_CRM_FAILURE = "ADD_PAST_PROJECTS_CRM_FAILURE";

// UPDATE PAST PROJECTS FOR CRM TEAM MEMBER
export const UPDATE_PAST_PROJECTS_CRM_LOADING =
  "UPDATE_PAST_PROJECTS_CRM_LOADING";
export const UPDATE_PAST_PROJECTS_CRM_SUCCESS =
  "UPDATE_PAST_PROJECTS_CRM_SUCCESS";
export const UPDATE_PAST_PROJECTS_CRM_FAILURE =
  "UPDATE_PAST_PROJECTS_CRM_FAILURE";

// DELETE CRM PAST PROJECT
export const DELETE_CRM_PAST_PROJECT_LOADING =
  "DELETE_CRM_PAST_PROJECT_LOADING";
export const DELETE_CRM_PAST_PROJECT_SUCCESS =
  "DELETE_CRM_PAST_PROJECT_SUCCESS";
export const DELETE_CRM_PAST_PROJECT_FAILURE =
  "DELETE_CRM_PAST_PROJECT_FAILURE";

// VENDOR REQUEST FINANCE
export const ONBOARD_VENDOR_LOADING = "ONBOARD_VENDOR_LOADING";
export const ONBOARD_VENDOR_SUCCESS = "ONBOARD_VENDOR_SUCCESS";
export const ONBOARD_VENDOR_FAILURE = "ONBOARD_VENDOR_FAILURE";
export const ONBOARD_VENDOR_RESET = "ONBOARD_VENDOR_RESET";

// SAVE PROJECT FOR LATER
export const SAVE_PROJECT_FOR_LATER_LOADING = "SAVE_PROJECT_FOR_LATER_LOADING";
export const SAVE_PROJECT_FOR_LATER_SUCCESS = "SAVE_PROJECT_FOR_LATER_SUCCESS";
export const SAVE_PROJECT_FOR_LATER_FAILURE = "SAVE_PROJECT_FOR_LATER_FAILURE";
export const SAVE_PROJECT_FOR_LATER_RESET = "SAVE_PROJECT_FOR_LATER_RESET";

// DELETE PROJECT TEAM MEMBER
export const DELETE_PROJECT_TEAM_MEMBER_LOADING =
  "DELETE_PROJECT_TEAM_MEMBER_LOADING";
export const DELETE_PROJECT_TEAM_MEMBER_SUCCESS =
  "DELETE_PROJECT_TEAM_MEMBER_SUCCESS";
export const DELETE_PROJECT_TEAM_MEMBER_FAILURE =
  "DELETE_PROJECT_TEAM_MEMBER_FAILURE";
export const DELETE_PROJECT_TEAM_MEMBER_RESET =
  "DELETE_PROJECT_TEAM_MEMBER_RESET";

// ENGINEERING DASHBOARD
export const ENGINEERING_DASHBOARD_LOADING = "ENGINEERING_DASHBOARD_LOADING";
export const ENGINEERING_DASHBOARD_SUCCESS = "ENGINEERING_DASHBOARD_SUCCESS";
export const ENGINEERING_DASHBOARD_FAILURE = "ENGINEERING_DASHBOARD_FAILURE";

// SCM DASHBOARD
export const SCM_DASHBOARD_LOADING = "SCM_DASHBOARD_LOADING";
export const SCM_DASHBOARD_SUCCESS = "SCM_DASHBOARD_SUCCESS";
export const SCM_DASHBOARD_FAILURE = "SCM_DASHBOARD_FAILURE";

// CONSTRUCTION DASHBOARD
export const CONSTRUCTION_DASHBOARD_LOADING = "CONSTRUCTION_DASHBOARD_LOADING";
export const CONSTRUCTION_DASHBOARD_SUCCESS = "CONSTRUCTION_DASHBOARD_SUCCESS";
export const CONSTRUCTION_DASHBOARD_FAILURE = "CONSTRUCTION_DASHBOARD_FAILURE";

// GET SCM DETAILS
export const GET_SCM_DETAILS_LOADING = "GET_SCM_DETAILS_LOADING";
export const GET_SCM_DETAILS_SUCCESS = "GET_SCM_DETAILS_SUCCESS";
export const GET_SCM_DETAILS_FAILURE = "GET_SCM_DETAILS_FAILURE";

// GENERATE SITE VISIT REPORT
export const GENERATE_SITE_VISIT_REPORT_LOADING =
  "GENERATE_SITE_VISIT_REPORT_LOADING";
export const GENERATE_SITE_VISIT_REPORT_SUCCESS =
  "GENERATE_SITE_VISIT_REPORT_SUCCESS";
export const GENERATE_SITE_VISIT_REPORT_FAILURE =
  "GENERATE_SITE_VISIT_REPORT_FAILURE";
